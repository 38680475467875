import { useState, useEffect } from 'react';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import get from 'lodash/get';
import getImages from '../../../utils/getImages';
import clsx from 'clsx';
import moment from 'moment';

import services from '../services';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E5E5E5',
  },
  width: {
    width: '80%',
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingBottom: '10%',
      marginBottom: 0,
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  titleText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '42px',
    lineHeight: '51px',
    color: '#444444',
    marginTop: '3.5rem',

    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  subTitleText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#999999',
    marginTop: '21px',
    marginBottom: '11px',
  },
  contentText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#444444',
    marginTop: '2.5rem',
    marginBottom: '10rem',
    paddingBottom: '10rem',

    [theme.breakpoints.between('md', 'lg')]: {
      marginRight: '10rem',
    },

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10rem',
    },
  }
}));

function BlogDetail() {
  const classes = useStyles();
  const { title } = useParams();
  const [detailResponse, setResponse] = useState(null);

  useEffect(() => {
    services.getBlogById({ slug: title }, (response, err) => {
      if (!err) {
        setResponse(response);
      }
    })
  }, []);

  const articleTitle = get(detailResponse, 'title');
  const articleImage = get(detailResponse, 'image.url');
  const articleContent = get(detailResponse, 'content');
  const articleCategory = get(detailResponse, 'category.name');
  const articleDate = moment(get(detailResponse, 'createdAt')).format('DD MMMM YYYY');

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.width}>
        <div className="w-11/12 lg:w-9/12 mx-auto">
          <div className="w-full py-5" style={{ borderBottom: '0.5px solid #999999' }}>
            <Link to="/blog" className="flex items-center space-x-2 text-primary-blue">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3333 7.16675L0.999921 7.16675" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.83325 13L0.999921 7.16667L6.83325 1.33333" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="font-inter font-normal text-base text-primary-blue">Back</span>
            </Link>
          </div>
          <div className="flex flex-col">
            <span className={classes.titleText}>
              {articleTitle}
            </span>

            <div className="flex items-center space-x-2 mt-3">
              <span className="text-base uppercase text-grey-2 font-inter">{articleCategory}</span>
              <span className="text-base text-grey-2">|</span>
              <span className="text-base text-grey-2 font-inter">{articleDate}</span>
            </div>
          </div>
          <img src={getImages(articleImage)} className={clsx(classes.image, 'mt-5')} />
          <div className="mt-10">

            <div className={classes.contentText} dangerouslySetInnerHTML={{ __html: `<div class="ck-content">${articleContent}</div>` }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default BlogDetail
