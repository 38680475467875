import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import style from '../index.module.scss';
import clsx from 'clsx';
import filter from 'lodash/filter';

// Component
import FeatureTutorial from './FeatureTutorial';
import Select from '../../../components/select';

// Utils
import axios from 'axios';
import getImages from '../../../utils/getImages';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  },
  headerImage: {
    '-webkit-transform': 'scaleX(-1)',
    transform: 'scaleX(-1)',
    order: 2,
    width: 300,
    height: 169,
    marginLeft: 'auto',
    marginRight: 20,

    [theme.breakpoints.up('sm')]: {
      order: 1,
      width: 'auto',
      height: 'auto',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  headerContainerText: {
    display: 'flex',
    order: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 44,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      order: 2,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    }
  },
  headerText: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '45px',
    lineHeight: '61px',
    color: '#FFFFFF',

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '32.74px',
    },
  },
  headerSubText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'justify',
    color: '#FFFFFF',
    marginTop: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: '300',
      marginTop: 10
    },
  },


  // Feature
  featureItem: {
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    height: 42,
    borderRadius: '6px',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      height: 59,
      width: 200,
      borderRadius: '11px',
      boxShadow: '0px 0px 12px 0.5px rgba(0, 0, 0, 0.12)',
    },
  },
  featureItemText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#444444',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  featureTitle: {
    textAlign: 'center',
    cursor: 'pointer',
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  },

  borderText: {
    width: '31px',
    borderBottom: '4px solid #0045E5',
  }
}));

function Feature(props) {
  const { withFooter } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(1);
  const [url, setUrl] = useState('https://apiastrapay-demo.kotadigivice.com/products?category.id=1');
  const [activeSubTab, setActiveSubTab] = useState(1);
  const [listProduct, setListProduct] = useState({
    response: [],
  });
  const [listTutorial, setListTutorial] = useState([]);

  const { categoryProduct } = props;

  const query = new URLSearchParams(window.location.search);
  const tab = query.get('tab');
  const product = query.get('product');

  useEffect(() => {
    const tabId = tab ? tab : activeTab;

    axios.get('https://apiastrapay-demo.kotadigivice.com/products', {
      params: {
        category: tabId ? tabId : 1,
      },
    })
      .then(data => {
        setListProduct({ response: data.data });

        const productId = product ? product : data.data[0].id;
        const products = filter(data.data, function (o){
          return o.id === Number(productId);
        });
        setActiveSubTab(products[0].id);
        setListTutorial(products[0].listTutorial);
      })
      .catch(err => console.log(err))
  }, [activeTab]);

  useEffect(() => {
    if (tab) {
      setActiveTab(Number(tab));
    }
  }, [tab]);

  useEffect(() => {
    axios.get(url)
    .then(data => setListProduct({response: data.data}))
    .catch(err => console.log(err))
  }, []);

  useEffect(() => {
    setActiveSubTab(Number(product));
  }, [product]);

  return (
    <div>
      <div className="flex-row justify-between mt-16 mb-2 overflow-x-auto hidden lg:flex">
        {categoryProduct.map((feature, index) => {
          const classActiveTabFeature = clsx('pb-2', {
            [classes.featureTitle + ' text-primary-blue']: activeTab === feature.id,
            [classes.featureTitle + ' text-dark-grey']: activeTab !== feature.id,
          });

          const classBorderText = clsx('transition duration-300', feature.name === 'Pajak' || feature.name === 'QRIS' ? {
            [classes.borderText]: activeTab === feature.id,
          } : {
            [style.borderText]: activeTab === feature.id,
          }
          )

          return (
            <div key={index}
              onClick={() => setUrl(`https://apiastrapay-demo.kotadigivice.com/products?category.id=${feature.id}`)}
            >
              <div
                role="button"
                className={classActiveTabFeature}
                onClick={() => {
                  setActiveTab(feature.id);
                }}
              >
                {feature.name}
              </div>

              <div className={classBorderText}></div>
            </div>
          )
        })}
      </div>

      <div className="px-3 lg:px-0 mt-12 block lg:hidden" 
        onChange={(e) => setUrl(`https://apiastrapay-demo.kotadigivice.com/products?category.id=${Number(e.target.value)+1}`)}
      >
        {/* <select
          className="form-select w-full px-3 py-2 font-semibold text-primary-blue rounded-md"
          onChange={(event) => setActiveTab(Number(event.target.value))}
          style={{ border: '0.5px solid #999999;' }}
        >
          {categoryProduct.map((feature, index) => {
            return (
              <option value={feature.id} key={index}>
                {feature.name}
              </option>
            )
          })}
        </select> */}
        <div className="py-2">
          <Select items={categoryProduct} onChange={(event) => setActiveTab(Number(event.target.value))} options={null} />
        </div>
      </div>

      <div className="w-full mt-3 lg:mt-5">
        <div className="grid grid-cols-2 gap-3 px-3 lg:flex lg:flex-row lg:justify-center lg:space-x-3 lg:px-0">
          {listProduct.response.map((feature) => {
            const image = getImages(feature.image.url);

            const isActive = activeSubTab === feature.id;
            const classTabActive = clsx({
              [classes.featureItem + ' border-2 border-primary-blue']: isActive,
              [classes.featureItem + ' border border-grey-2']: !isActive
            });

            return (
              <div
                key={feature.id}
                role="button"
                className={classTabActive}
                onClick={() => {
                  setActiveSubTab(feature.id)
                  setListTutorial(feature.listTutorial);
                }}
              >
                <img src={image} className="w-8 h-8 object-contain my-auto mr-3 lg:mr-0" />
                <div className="self-center">
                  <div className={classes.featureItemText}>
                    {feature.name}
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        {withFooter && (
          <div
            className="flex justify-center my-4 py-3 px-5 lg:py-5 mx-3 lg:mx-0"
            style={{ background: 'rgba(196, 196, 196, 0.2)', borderRadius: '10px' }}
          >
            {listProduct.response.map((feature) => {
              const activeTabSubFeature = activeSubTab === feature.id;

              if (activeTabSubFeature) {
                return (
                  <div className="self-center text-center" dangerouslySetInnerHTML={{ __html: feature.detail}}/>
                )
              }
            }
            )}
          </div>
        )}
      </div>

      <FeatureTutorial list={listTutorial} />
    </div>
  );
}

Feature.propTypes = {
  withFooter: PropTypes.bool,
  data: PropTypes.array,
  categoryProduct: PropTypes.array,
};

Feature.defaultProps = {
  withFooter: true,
};

export default Feature;