import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Loading from '../../../components/loading';

// Component
import InputSearch from '../../../components/input/Search';
import Select from '../../../components/select';

// Utils
import get from 'lodash/get';
import services from '../services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    backgroundColor: '#003BC4',
    paddingTop: 70,
    paddingBottom: 70,
    backgroundImage: `url(${require('../../../assets/karir/imageBuilding.png').default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    [theme.breakpoints.down('md')]: {
      backgroundColor: '#fff',
      paddingTop: 28,
      paddingBottom: 0,
      backgroundImage: 'none',
    },
  },
  title: {
    fontFamily: 'Nunito-Regular',
    fontWeight: 800,
    fontSize: 45,
    lineHeight: '60px',
    color: '#fff',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  searchInput: {
    display: 'block',
    position: 'relative',
    width: 350,
    marginTop: 30,

    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  findKarir: {
    backgroundColor: '#FFF',
    width: 965,
    borderRadius: 11,
    padding: 45,
    marginTop: 45,

    [theme.breakpoints.only('md')]: {
      width: '90%',
      padding: 0,
      marginTop: 43,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
      marginTop: 43,
    },
  },
  findKarirTitle: {
    fontFamily: 'Inter-Regular',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',

    [theme.breakpoints.only('md')]: {
      display: 'block',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  findKarirSelect: {
    width: 260,
    border: '0.5px solid #999999',
    borderRadius: '8px',

    [theme.breakpoints.down('md')]: {
      width: '90%',
      border: '0.5px solid #999999',
      borderRadius: '8px',
    },
  },
  findKarirJobTitle: {
    fontFamily: 'Inter-Regular',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: '#003BC4',
  },
  findKarirTable: {
    [theme.breakpoints.only('md')]: {
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  findKarirMobile: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  findKarirMobileItem: {
    paddingBottom: 18,
    paddingTop: 18,
    borderBottom: '1px solid #E1E1E1',
  },
}));

function FindKarir(props) {
  const classes = useStyles();
  const { response } = props;

  const title = get(response, 'KARIR_LIST_TITLE');

  const [listJobPosition, setListJobPosition] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listWorkType, setWorkType] = useState([]);
  const [isload, setIsLoad] = useState(false);

  const [params, setParams] = useState({
    page: 1,
  });

  const pagination = listJobPosition.total > 1 ? (listJobPosition.total / 5).toFixed() : listJobPosition.total;

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      const { search } = values;
      setParams(prev => ({ ...prev, search }))
    },
  });

  useEffect(() => {
    setIsLoad(true);
    // Get Job
    services.getJob({
      _limit: 5,
      _start: (params.page - 1) * 5,
      ...(params.departmentId) && { ['department.id']: parseInt(params.departmentId) },
      ...(params.workTypeId) && { ['workType.id']: parseInt(params.workTypeId) },
      _q: params.search,
    },
      {
        success: async (response) => {
          if (params.isMore) {
            return setListJobPosition(prev => ({
              total: response.total,
              data: [...prev.data, ...response.data],
            }));
          }

          await setListJobPosition(response);
          await setIsLoad(false);
        },
        error: () => { }
      });
  }, [params]);

  useEffect(() => {
    // Get Department
    services.getDepartement({
      success: (response) => {
        setListDepartment(response);
      },
      error: () => { }
    })

    services.getWorkType({
      success: (response) => {
        setWorkType(response);
      },
      error: () => { }
    })
  }, []);

  // const classSelectInput = clsx(classes.findKarirSelect, 'form-select');

  return (
    <div className={classes.root}>
      <div className="flex flex-col items-center justify-center">
        <span className={classes.title}>
          {title}
        </span>

        <form className={classes.searchInput} onSubmit={formik.handleSubmit}>
          <InputSearch
            name="search"
            placeholder="Ketik untuk mencari"
            value={formik.values.search}
            onChange={formik.handleChange}
            onClose={() => {
              formik.resetForm();
              setParams(prev => ({ ...prev, search: null }));
            }}
          />
        </form>

        <div className={classes.findKarir}>
          <div className="w-full lg:flex items-center justify-between">
            <span className={classes.findKarirTitle}>Temukan Pekerjaanmu</span>

            <div className="flex flex-col lg:flex-row items-center space-y-2 lg:space-y-0 space-x-0 lg:space-x-3">
              {/* <select
                className={classSelectInput}
                onChange={ev => setParams(prev => ({
                  ...prev,
                  departmentId: parseInt(ev.target.value),
                }))}
              >
                <option value="">Semua Department</option>
                {listDepartment.map((item) => (
                  <option value={item.id} key={item.id}>{item.name}</option>
                ))}
              </select> */}
              <div className="px-5 w-full">
                <Select items={listDepartment}
                  onChange={ev => setParams(prev => parseInt(ev.target.value) !== null ? ({
                    ...prev,
                    departmentId: parseInt(ev.target.value)+1,
                  })
                  :
                  ({
                    ...prev,
                    departmentId: null,
                  }))
                }
                  options={'Semua Department'}
                />
              </div>
              {/* <select
                className={classSelectInput}
                onChange={ev => setParams(prev => ({
                  ...prev,
                  workTypeId: parseInt(ev.target.value),
                }))}
              >
                <option value="">Semua Jenis</option>
                {listWorkType.map((item) => (
                  <option value={item.id} key={item.id}>{item.name}</option>
                ))}
              </select> */}
              <div className="px-5 w-full">
                <Select items={listWorkType}
                  onChange={ev => setParams(prev => ({
                    ...prev,
                    workTypeId: parseInt(ev.target.value)+1,
                  }))}
                  options={'Semua Jenis'}
                />
              </div>
            </div>
          </div>

          <Loading className="h-56" isLoading={isload} isEmpty={listJobPosition?.data?.length === 0}>
            <Table className={classes.findKarirTable}>
              <TableBody>
                {listJobPosition?.data?.map((item, index) => {
                  const jobPosition = get(item, 'jobTitle');
                  const department = get(item, 'department.name');
                  const workType = get(item, 'workType.name');
                  const url = `/karir/job/${item.id}`;

                  return (
                    <TableRow style={{ borderBottom: '1px solid #E1E1E1' }} key={index}>
                      <TableCell>
                        <Link to={url} className={classes.findKarirJobTitle}>
                          {jobPosition}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <span className="font-inter font-normal text-sm leading-3">{department}</span>
                      </TableCell>
                      <TableCell>
                        <span className="font-inter font-normal text-sm leading-3">{workType}</span>
                      </TableCell>
                      <TableCell>
                        <Link to={url} className="focus:outline-none">
                          <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.66699 6H12.3337" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.66699 1.33337L12.3337 6.00004L7.66699 10.6667" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Loading>

          <div className={clsx(classes.findKarirMobile, 'flex flex-col lg:hidden')}>
            {listJobPosition?.data?.map((item, index) => {
              const jobPosition = get(item, 'jobTitle');
              const department = get(item, 'department.name');
              const workType = get(item, 'workType.name');
              const url = `/karir/job/${item.id}`;

              return (
                <div className={classes.findKarirMobileItem} key={index}>
                  <Link to={url} className="font-hind text-primary-blue font-inter font-semibold text-base">
                    {jobPosition}
                  </Link>
                  <div className="flex items-center justify-between">
                    <span className="font-inter font-light text-sm text-dark-grey">
                      Dept. {department}
                    </span>
                    <span className="font-inter font-light text-sm text-dark-grey">
                      {workType}
                    </span>
                  </div>
                </div>
              )
            })}

            <button
              className={clsx('w-full rounded-md bg-oren py-3 text-white font-inter font-semibold mt-8', {
                'hidden': listJobPosition?.total === listJobPosition?.data?.length,
              })}
              onClick={() => setParams(prev => ({ ...prev, page: prev.page + 1, isMore: true }))}
            >
              Menampilkan lebih banyak
            </button>
          </div>

          {!isload && listJobPosition?.data?.length !== 0 && (
            <div className="lg:flex items-center justify-center mt-5 hidden">
              <Pagination
                page={params.page}
                count={pagination}
                color="primary"
                onChange={(ev, page) => setParams(prev => ({ ...prev, page }))}
                hideNextButton
                hidePrevButton
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FindKarir.propTypes ={
  response: PropTypes.any,
};

export default FindKarir;