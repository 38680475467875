import axios from 'axios';
import endpoint from '../../../utils/endpoint';

async function getProduct(callback) {
  const url = endpoint.product;

  try {
    const response = await axios.get(url);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function getCategoryProduct(callback) {
  const url = endpoint.categoryProduct;

  try {
    const response = await axios.get(url);
    await callback.success(response);
  } catch (error) {
    await callback.error(error);
  }
}

export default {
  getProduct,
  getCategoryProduct,
};
