import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import IconCloseOutlined from '@material-ui/icons/CloseOutlined';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

function Search(props) {
  const { className, classContainer, name, onClose, value, ...other } = props;
  const classes = useStyles();

  function handleClose() {
    onClose();
  }

  return (
    <div className={clsx(classes.root, 'relative', classContainer)}>
      <input
        name={name}
        className={clsx('rounded-full w-full bg-white text-sm text-dark-grey px-4 py-3 border border-gray-400 focus:border-primary-blue', className)}
        value={value}
        {...other}
      />

      {value && (
        <IconCloseOutlined
          onClick={() => handleClose()}
          className="absolute top-0 right-0 bottom-0 mr-12 my-auto text-dark-grey"
        />
      )}
      <button className="absolute right-0 top-0 bottom-0 my-auto mr-1 bg-primary-blue w-10 h-10 rounded-full flex items-center justify-center focus:outline-none" type="submit">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 12L15.0001 15.0001" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="bevel" />
          <circle cx="7.14536" cy="7.14533" r="5.56989" transform="rotate(-8.07909 7.14536 7.14533)" stroke="white" strokeWidth="1.5" />
        </svg>
      </button>
    </div>
  );
}

Search.propTypes = {
  classContainer: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};

Search.defaultProps = {
  onClose: () => {},
};

export default Search;
