import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import GlobalServices from '../../../services';
import { get } from 'lodash';
import getImages from '../../../utils/getImages';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '80%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  item: {
    width: 158,
    height: 218,
  },
  itemTitle: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
  },

  // Feature
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    height: 42,
    border: '0.5px solid #999999',
    borderRadius: '6px',
    backgroundColor: '#fff',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      height: 59,
      width: 200,
      borderRadius: '11px',
      border: 'none',
      backgroundColor: '#fff',
      boxShadow: '0px 0px 12px 0.5px rgba(0, 0, 0, 0.12)',
    },
  },
  featureItemText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#444444',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  featureTitle: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#003BC4',
    fontSize: '45px',
    lineHeight: '61px',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.only('md')]: {
      display: 'flex',
      justifyContent: 'center',
      color: '#003BC4',
      fontSize: '35px',
      lineHeight: '61px',
    },

    [theme.breakpoints.down('sm')]: {
      color: '#444',
      fontSize: '18px',
      lineHeight: '24px',
    }
  },
}));

function FeatureProduct() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(1);
  const [listProduct, setListProduct] = useState([]);
  const [listCategory, setListCategory] = useState([]);

  useEffect(() => {
    GlobalServices.getProduct({
      'category.id': activeTab,
    }, (response, err) => {
      if (!err) {
        setListProduct(response);
      }
    });
  }, [activeTab]);

  useEffect(() => {
    GlobalServices.getCategoryProduct((response, err) => {
      if (!err) {
        setListCategory(response);
      }
    });
  }, []);

  return (
    <div style={{ backgroundColor: '#F7F7F7' }} className={clsx('py-20 px-4 lg:px-8 xl:px-0')}>
      <span className={classes.featureTitle}>
        Fitur-fitur AstraPay
      </span>

      <div className={clsx(classes.root, 'w-full flex flex-col items-center mx-auto')}>
        <div className="lg:flex justify-between my-10 hidden">
          {listCategory.map((itemFeature, index) => {
            const isActive = activeTab == itemFeature.id;
            const classTitle = clsx({
              [classes.itemTitle + ' text-dark-grey']: !isActive,
              [classes.itemTitle + ' text-primary-blue']: isActive,
            });

            return (
              <button className="flex flex-col space-y-2 focus:outline-none" key={index} onClick={() => setActiveTab(Number(itemFeature.id))}>
                <span className={classTitle}>{itemFeature.name}</span>
                {isActive && (
                  <div className="bg-primary-blue" style={{ height: 5, width: 50 }} />
                )}
              </button>
            )
          })}
        </div>

        <div className="w-full lg:flex justify-center hidden">
          <div className="flex space-x-10">
            {listProduct.map((itemProduct, itemProductIndex) => {
              const classActive = clsx('bg-white rounded-lg px-4 py-4 w-full flex flex-col justify-between border border-white hover:border hover:border-primary-blue', classes.item);
              const image = getImages(get(itemProduct, 'image.url'));
              const url = `/product_feature?tab=${activeTab}&product=${itemProduct.id}`;

              return (
                <div
                  className={classActive}
                  style={{ boxShadow: '0px 0px 12px 0.5px rgba(0, 0, 0, 0.12)' }}
                  key={itemProductIndex}
                >
                  <div className="flex flex-col items-center justify-center mt-4">
                    <img className="h-14 w-14 object-contain" src={image} />
                    <span className="font-inter font-semibold text-lg text-center mt-2">
                      {itemProduct.name}
                    </span>
                  </div>

                  <Link className="bg-primary-blue py-1 text-white rounded-full font-inter font-semibold w-full text-center hover:text-white" to={url}>
                    Cari tahu
                  </Link>
                </div>
              )
            })}
          </div>
        </div>

        <div className="mt-5 block lg:hidden">
          <select
            className="form-select w-full px-3 py-2 font-semibold text-primary-blue rounded-md"
            onChange={(event) => setActiveTab(Number(event.target.value))}
            style={{ border: '0.5px solid #999999;' }}
          >
            {listCategory.map((feature, index) => {
              return (
                <option value={feature.id} key={index}>
                  {feature.name}
                </option>
              )
            })}
          </select>
        </div>

        <div className="w-full grid grid-cols-2 gap-3 lg:hidden mt-3">
          {listProduct.map(featureItem => {
            const image = getImages(get(featureItem, 'image.url'));
            const url = `/product_feature?tab=${activeTab}&product=${featureItem.id}`;

            return (
              <Link
                to={url}
                key={featureItem.id}
                className={classes.featureItem}
              >
                <div className="flex items-center space-x-2">
                  <img src={image} className="w-6 h-6 object-contain mr-3 lg:mr-0" />
                  <span className={classes.featureItemText}>
                    {featureItem.name}
                  </span>
                </div>
                <svg className="text-primary-blue" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 6.83325H14.3333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.5 1L14.3333 6.83333L8.5 12.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default FeatureProduct;