import PropTypes from 'prop-types';
import { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import style from '../index.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from 'react-slick';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#D7E3F3',
    backgroundImage: `url('${require('../../../assets/banner/Banner 2.png').default}')`,
    backgroundSize: '60% auto',
    backgroundPosition: 'bottom left',
    backgroundRepeat: 'no-repeat',
    height: 360,

    [theme.breakpoints.only('sm')]: {
      backgroundSize: '40% auto',
    },
  },
  scrolling: {
    paddingTop: 65,
  },
  titleScrolling: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '21px',

    [theme.breakpoints.only('md')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },

    [theme.breakpoints.only('sm')]: {
      fontSize: '20px',
      lineHeight: '21px',
    },
  },
  imageSlider: {
    width: 'auto',
    maxHeight: 510,
    [theme.breakpoints.only('xl')]: {
      width: 'auto',
      maxHeight: 510,
    },

    [theme.breakpoints.only('lg')]: {
      width: '40%',
      maxHeight: 410,
    },

    [theme.breakpoints.only('md')]: {
      width: '30%',
      maxHeight: 360,
    },

    [theme.breakpoints.only('sm')]: {
      width: '80%',
      maxHeight: 260,
    },
  },
}));


SamplePrevArrow.propTypes = {
  onClick: PropTypes.func,
}

SampleNextArrow.propTypes = {
  onClick: PropTypes.func,
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <img onClick={onClick} src={require('../../../assets/icon/arrow top.png').default} className="cursor-pointer" />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <img onClick={onClick} src={require('../../../assets/icon/arrow bottom.png').default} className="cursor-pointer" />
  );
}

function Feature(props) {
  const { titleScrolling, itemScrolling } = props;
  const classes = useStyles();
  const [active, setActive] = useState(0);
  const [activeDesktop, setActiveDesktop] = useState(2);
  const [carousel, setCaraousel] = useState(null);


  return (
    <div>
      <div className="hidden lg:flex" style={{ backgroundColor: '#d6e3f0' }}>
        <img src={require('../../../assets/banner/Banner 2.png').default} className={style.scrollingImage} width={550} alt="" />

        <div className="flex items-center">
          <div className={style.easier}>
            {titleScrolling}
          </div>

          <div className="lg:w-6/12 xl:w-4/12">
            {itemScrolling.length && (
              <Slick
                autoplay
                lazyLoad
                vertical
                verticalSwiping
                slidesToShow={3}
                infinite
                beforeChange={(old, newIndex) => {
                  if (newIndex + 1 === itemScrolling.length) {
                    return setActiveDesktop(0)
                  }

                  return setActiveDesktop(newIndex + 1)
                }}
                arrows={true}
                nextArrow={<SampleNextArrow />}
                prevArrow={<SamplePrevArrow />}
                responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: true,
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: true,
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                      infinite: true,
                    }
                  }
                ]}
              >
                {itemScrolling?.map((scroll, index) => {
                  const isActive = index === activeDesktop;
                  const classActive = clsx('transform w-full', style.li, {
                    ['text-primary-blue hover:text-primary-blue']: isActive,
                    ['text-primary-blue-500 hover:text-primary-blue-500']: !isActive,
                  });
                  const url = `/product_feature${scroll.link}`;

                  return (
                    <Link to={url} className={classActive} key={index}>{scroll.name}</Link>
                  )
                })}

              </Slick>
            )}
          </div>
        </div>
      </div>

      <div className={clsx(classes.root, 'flex justify-center lg:hidden')}>
        <div className={clsx(classes.scrolling, 'w-full flex')}>
          <div className="flex-1 text-right">
            <span className={classes.titleScrolling} style={{ color: '#444' }}>Lebih Gampang</span>
          </div>
          <div className="flex-1 pl-3">
            <div className="flex items-start space-x-2">
              <div className="w-9/12">
                <Slick ref={refCarousel => setCaraousel(refCarousel)} beforeChange={(old, newIndex) => setActive(newIndex)} centerPadding={40} vertical verticalSwiping autoplay slidesToShow={3} arrows={false}>
                  {itemScrolling?.map((item, index) => {
                    const url = `/product_feature${item.link}`;
                    const classActive = clsx('w-full transform', classes.titleScrolling, {
                      ['text-primary-blue hover:text-primary-blue border-b-2 border-primary-blue']: index === active,
                      ['text-primary-blue-500 hover:text-primary-blue-500']: index !== active,
                    });

                    return (
                      <div className="w-full" key={index}>
                        <Link to={url} className={classActive}>
                          {item.name}
                        </Link>
                      </div>
                    )
                  })}
                </Slick>
              </div>

              <button className="focus:outline-none mt-3" onClick={() => carousel.slickNext()}>
                <svg width="10" height="10" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.16795 4.75192L1.03647 1.5547C0.59343 0.890146 1.06982 3.8331e-07 1.86852 3.13486e-07L6.13148 -5.91942e-08C6.93018 -1.29018e-07 7.40657 0.890145 6.96353 1.5547L4.83205 4.75192C4.43623 5.34566 3.56377 5.34566 3.16795 4.75192Z" fill="#003BC4" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Feature.propTypes = {
  titleScrolling: PropTypes.string,
  itemScrolling: PropTypes.any,
};

Feature.defaultProps = {
  itemScrolling: [],
};

export default Feature;