import { makeStyles, createStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Utils
import get from 'lodash/get';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'block',
    paddingTop: 215,
    paddingBottom: 120,
    backgroundColor: '#F7F7F7',
    backgroundImage: [`url(${require('../../../assets/karir/heroLeft.png').default})`, `url(${require('../../../assets/karir/heroRight.png').default})`],
    backgroundRepeat: ['no-repeat', 'no-repeat'],
    backgroundPosition: ['left bottom', 'right bottom'],
    backgroundSize: 'auto 687px',

    [theme.breakpoints.down('md')]: {
      backgroundImage: [`url(${require('../../../assets/karir/heroMobileLeft.png').default})`, `url(${require('../../../assets/karir/heroMobileRight.png').default})`],
      paddingTop: 70,
      paddingBottom: 85,
      backgroundSize: 'auto 250px',
    },
  },
  title: {
    fontFamily: 'Nunito-Regular',
    fontWeight: 800,
    fontSize: 45,
    lineHeight: '60px',
    color: '#003BC4',
    width: 650,
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      width: 285,
      textAlign: 'left',
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  subtitle: {
    fontFamily: 'Inter-Regular',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
    color: '#003BC4',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  body: {
    fontFamily: 'Inter-Regular',
    fontWeight: 400,
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: '24px',
    color: '#444',
    textAlign: 'justify',
    width: 500,
    
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

function Header(props) {
  const { response } = props;
  const classes = useStyles();

  const title = get(response, 'KARIR_TITLE');
  const description = get(response, 'KARIR_DESCRIPTION');

  return (
    <div className={classes.root}>
      <div className="flex flex-col items-start lg:items-center justify-center space-y-8 w-11/12 mx-auto lg:mx-0 lg:w-full">
        <p className={classes.title}>{title}</p>
        <p className={classes.subtitle}>Tentang AstraPay</p>
        <p className={classes.body}>{description}</p>
      </div>
    </div>
  );
}

Header.propTypes ={
  response: PropTypes.any,
};

export default Header;