import { Component } from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Component
import FeatureBanner from './components/FeatureBanner';
import Feature from './components/Feature';

// Utils 
import services from './services';
import globalServices from '../../services';

const styles = theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  },
  headerImage: {
    order: 1,
    width: 'auto',
    height: 'auto',
    marginLeft: 0,
    marginRight: 0,
    '-webkit-transform': 'scaleX(1)',

    [theme.breakpoints.down('sm')]: {
      '-webkit-transform': 'scaleX(1)',
      transform: 'scaleX(1)',
      order: 2,
      width: 300,
      height: 169,
    },

    [theme.breakpoints.down('xs')]: {
      '-webkit-transform': 'scaleX(-1)',
      transform: 'scaleX(-1)',
      order: 2,
      width: 300,
      height: 169,
      marginLeft: 'auto',
      marginRight: 20,
    },

    [theme.breakpoints.only('md')]: {
      order: 1,
      width: 350,
      height: 250,
      '-webkit-transform': 'scaleX(1)',
    },
  },
  headerContainerText: {
    display: 'flex',
    order: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 44,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      order: 2,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    }
  },
  headerText: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '45px',
    lineHeight: '61px',
    color: '#FFFFFF',

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '32.74px',
    },

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  headerSubText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'justify',
    color: '#FFFFFF',
    marginTop: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: '300',
      marginTop: 10
    },

    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },


  // Feature
  featureItem: {
    display: 'flex',

    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    height: 42,
    border: '0.5px solid #999999',
    borderRadius: '6px',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      height: 59,
      width: 200,
      borderRadius: '11px',
      border: 'none',
      boxShadow: '0px 0px 12px 0.5px rgba(0, 0, 0, 0.12)',
    },
  },
  featureItemText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#444444',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
});

class ProductFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFeature: 0,
      selectedSubFeature: 1,
      toggleChange: 1,
      data: [],
      categoryProduct: [],
      pageSetting: {}
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });

    // Get Product
    services.getProduct({
      success: (data) => {
        this.setState({
          data: data.data,
        })
      },
      error: (error) => {
        console.log(error)
      }
    }),
    services.getCategoryProduct({
      success: (data) => {
        this.setState({
          categoryProduct: data.data,
        })
      },
      error: (error) => {
        console.log(error)
      }
    }),
    globalServices.getPageSetting((response, err) => {
      if (!err) {
        this.setState({pageSetting: response});
      }
    })
  }

  href = (e, url) => {
    e.preventDefault();
    this.props.history.push(url);
  }

  onSelectFeature = (index) => {
    this.setState({
      selectedFeature: index
    })
  }

  onSelectSubFeature = (id) => {
    this.setState({
      selectedSubFeature: id
    })
  }

  onToggleImage = (index) => {
    this.setState({
      toggleChange: index,
    })
  }

  render() {
    const { classes } = this.props;
    const title = this.state.pageSetting.PRODUCT_TITLE;
    const description = this.state.pageSetting.PRODUCT_DESCRIPTION;
    return (
      <>
        <Header />
        <div className={classes.header} style={{ background: '#003BC4' }}>
          <img
            src={require('../../assets/banner/productFeature.svg').default}
            className={classes.headerImage}
            alt=""
          />

          <div className={classes.headerContainerText}>
            <div className="self-center justify-end items-stretch">
              <div className={classes.headerText}>
                {/* Semua Jadi
                <br />
                Lebih Gampang
                <br />
                dengan AstraPay */}
                {title}
              </div>

              <div className={classes.headerSubText}>
                {/* Yuk, kenalan dengan fitur-fitur Astrapay
                <br />
                dan cara menggunakannya! */}
                {description}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-10/12 mx-auto">
          <Feature data={this.state.data} categoryProduct={this.state.categoryProduct} />
        </div>
        <FeatureBanner />
        <Footer />
      </>
    )
  }
}

ProductFeature.propTypes = {
  history: PropTypes.any,
  classes: PropTypes.object,
};


export default withStyles(styles)(ProductFeature);