import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NativeSelect as MaterialSelect, withStyles } from '@material-ui/core';
// import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    width: '100%',
    fontWeight: 400,
    border: '0.5px solid #999999',
    borderRadius: '0.375rem',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    color: '#003BC4',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#003BC4',
      fontWeight: 600,
    },
  },
  optionClass: {
    color: '#FFFFF'
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

// Select with .map options like pagination component
function Select(props) {
  const { className, items,onChange, options,...other } = props;
  const classes = useStyles();

  return (
    <MaterialSelect className={clsx(classes.root, className, '')} onChange={onChange} input={<BootstrapInput />} {...other}>
      {options !== null && 
        <option value="" className={classes.optionClass}>{options}</option>
      }
      {items.map((item,index) => (
        <option key={index} value={index}>
          {item.name}
        </option>
      ))}
    </MaterialSelect>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  items: PropTypes.any,
  options: PropTypes.string,
  onChange: PropTypes.func,
};

export default Select;