import history from './history.js'
import Home from './pages/home';
import ProductFeature from './pages/productFeature';
import Partner from './pages/partner';
import Promo from './pages/promo';
import HelpCenter from './pages/helpCenter/index';
import Blog from './pages/blog'
import FAQ from './pages/faq/index';
import Terms from './pages/tnc/index';
import Privacy from './pages/privacy/index';
import Karir from './pages/karir';
import KarirJob from './pages/karir/pages/DetailJob';
import KarirJobApply from './pages/karir/pages/ApplyJob';
import PromoDetail from './pages/promo/components/PromoDetail';
import BlogDetail from './pages/blog/pages/BlogDetail';
import NotFound from './pages/notfound';

// Material UI Theme
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { Switch, Route, BrowserRouter } from 'react-router-dom';

function App() {
  let route = (
    <BrowserRouter>
      <Switch>
        <Route exact history={history} path="/" component={Home}></Route>
        <Route exact history={history} path="/product_feature" component={ProductFeature}></Route>
        <Route exact history={history} path="/partner" component={Partner}></Route>
        <Route exact history={history} path="/promo" component={Promo}></Route>
        <Route exact history={history} path="/promo/:id" component={PromoDetail}></Route>
        <Route exact history={history} path="/help_center" component={HelpCenter}></Route>
        <Route exact history={history} path="/blog" component={Blog}></Route>
        <Route exact history={history} path="/blog/:title" component={BlogDetail}></Route>
        <Route exact history={history} path="/faq" component={FAQ}></Route>
        <Route exact history={history} path="/terms_and_condition" component={Terms}></Route>
        <Route exact history={history} path="/privacy_policy" component={Privacy}></Route>
        <Route exact history={history} path="/karir" component={Karir} />
        <Route exact history={history} path="/karir/job/:id" component={KarirJob} />
        <Route history={history} path="/karir/job/:id/apply" component={KarirJobApply} />

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#003BC4',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {route}
    </ThemeProvider>
  );
}

export default App;
