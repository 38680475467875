import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';

import Navbar from '../../../components/header';
import Footer from '../../../components/footer';
import { useState, useEffect } from 'react';

import get from 'lodash/get';
import services from '../services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    height: 1465,
    backgroundImage: `url(${require('../../../assets/karir/peopleBackground.png').default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '630px 1465px',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      backgroundImage: 'none',
    },
  },
  job: {
    paddingTop: 140,

    [theme.breakpoints.down('md')]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  jobLeftTitle: {
    fontFamily: 'Nunito-Regular',
    fontWeight: 800,
    fontSize: 45,
    lineHeight: '61px',
    color: '#fff'
  },
  jobLeftParagraf: {
    fontFamily: 'Inter-Regular',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    width: 313,
    color: '#fff',
    marginTop: 20,
  },
  jobTitle: {
    fontFamily: 'Inter-Regular',
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '36px',
    color: '#444',
    marginTop: 10,
  },
  jobLocation: {
    fontFamily: 'Inter-Regular',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#999',
  },
  jobRequired: {
    fontFamily: 'Inter-Regular',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
    color: '#444',
  },
  jobRequiredItem: {
    fontFamily: 'Inter-Regular',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#999',
  },
  jobBreadcrumb: {
    display: 'flex',
  
    "& li::after": {
      content: '>',
      display: 'block',
      width: 10,
      height: 10,
      color: '#003BC4',
    },
  },
  description: {
    "& *": {
      fontFamily: 'Inter-Regular',
    },
  },
}));

function DetailJob() {
  const classes = useStyles();
  const { id } = useParams();

  const [jobDetail, setJobDetail] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0 });

    services.getDetailJob({
      id,
    },
    {
      success: (response) => {
        setJobDetail(response);
      },
      error: () => {}
    })
  }, []);

  const jobId = get(jobDetail, 'id');
  const jobTitle = get(jobDetail, 'jobTitle');
  const location = get(jobDetail, 'location');
  const department = get(jobDetail, 'department.name');
  const description = get(jobDetail, 'detail');
  const url = `/karir/job/${jobId}/apply`;

  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <div className={clsx(classes.job, 'flex w-10/12 lg:w-8/12 xl:w-10/12 mx-auto')}>
          <div className="w-1/2 hidden lg:block">
            <div className="flex flex-col">
              <span className={classes.jobLeftTitle}>
                Daftarkan <br />
                Dirimu!
              </span>
              <p className={classes.jobLeftParagraf}>
                Mari bergabung dengan AstraPay dalam misi mempermudah dan
                mempercepat transaksi digital masyarakat Indonesia.
            </p>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="flex flex-col">
              <ul className={clsx(classes.jobBreadcrumb, 'space-x-2')}>
                <li className={clsx('font-inter text-primary-blue text-base font-normal')}>Career &gt;</li>
                <li className={clsx('font-inter text-primary-blue text-base font-normal')}>All Jobs &gt;</li>
                <li className={clsx('font-inter text-grey-2 text-base font-normal')}>{jobTitle}</li>
              </ul>
              <span className={classes.jobTitle}>{jobTitle}</span>
              <p className={classes.jobLocation}>{department}   |  {location}</p>

              <div className="mt-10">
                {/* <span className={classes.jobRequired}>Job Description</span> */}

                <div className={classes.description} dangerouslySetInnerHTML={{ __html: description }}></div>
                {/* <ul className="list-disc mt-2">
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                </ul> */}
              </div>

              {/* <div className="mt-10">
                <span className={classes.jobRequired}>Requirements</span>

                <ul className="list-disc mt-2">
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li className={classes.jobRequiredItem}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                </ul>
              </div> */}

              <Link to={url} className="w-full rounded-md bg-oren py-3 text-white font-inter font-semibold mt-8 text-center hover:text-white">
                Daftar Sekarang
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailJob;