import axios from 'axios';
import endpoint, { reCaptcha } from '../../../utils/endpoint';

async function postTicket(params, callback) {
  const { name, email, topic, message } = params;
  const url = endpoint.ticket;
  const config = {
    method: 'post',
    data: {
      subject: topic,
      body: message,
      locale_id: 77,
      name,
      email,
    },
  };

  try {
    const response = await axios(url, config);
    await callback(response, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function verifyResponse(token, callback) {
  const url = 'https://www.google.com/recaptcha/api/siteverify';
  const config = {
    method: 'post',
    data: {
      secret: reCaptcha,
      response: token,
    },
  };

  try {
    const response = await axios(url, config);
    await callback(response, null);
  } catch (error) {
    await callback(null, error);
  }
}

export default {
  postTicket,
  verifyResponse,
};