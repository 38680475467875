import axios from 'axios';
import endpoint from '../../../utils/endpoint';

async function getKarir(callback) {
  const url = endpoint.karir;
  
  try {
    const response = await axios.get(url);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function getJob(params, callback) {
  const url = endpoint.job;
  const config = {
    method: 'get',
    params,
  };

  try {
    const total = await axios(url + '/count', config);
    const response = await axios(url, config);
    await callback.success({ data: response.data, total: total.data });
  } catch (error) {
    await callback.error(error);
  }
}

async function getDetailJob(params, callback) {
  const url = endpoint.detailJob + params.id;
  const config = {
    method: 'get',
  };

  try {
    const response = await axios(url, config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function applyJob(params, callback) {
  const url = endpoint.applyJob;
  const config = {
    method: 'post',
    data: params.data,
  };

  try {
    const response = await axios(url, config);
    await callback.success(response);
  } catch (error) {
    await callback.error(error);
  }
}

async function getDepartement(callback) {
  const url = endpoint.departement;
  const config = {
    method: 'get',
  };

  try {
    const response = await axios(url, config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function getWorkType(callback) {
  const url = endpoint.workType;
  
  try {
    const response = await axios.get(url);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function getEducation(callback) {
  const url = endpoint.education;

  try {
    const response = await axios.get(url);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

export default {
  getEducation,
  getDepartement,
  getWorkType,
  getKarir,
  getJob,
  getDetailJob,
  applyJob,
};
