import { Component } from 'react'
import PropTypes from 'prop-types';
import style from './index.module.scss'
import Header from '../../components/header';
import Footer from '../../components/footer';
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Pagination from '../../components/pagination';

// Utils
import services from './services/index';
import getImages from '../../utils/getImages';
import globalServices from '../../services';

const styles = (theme) => ({
  image: {
    width: '100%',
  },
  background: {
    backgroundColor: '#F7F7F7',
  },
  container: {
    backgroundImage: `url('${require('../../assets/banner/partner.png').default}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: '100% 580px',

    [theme.breakpoints.down('md')]: {
      backgroundSize: '100% 300px',
      backgroundPosition: 'top',
    },

    [theme.breakpoints.only('md')]: {
      backgroundSize: '100% 400px',
      backgroundPosition: 'top',
    },

    [theme.breakpoints.down('sm')]: {
      backgroundSize: '100% 315px',
      backgroundPosition: 'top',
    },
  },
  header: {
    paddingTop: 230,
    paddingBottom: 100,

    [theme.breakpoints.down('md')]: {
      paddingTop: 100,
      paddingBottom: 100,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  mobileHeader: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },

    [theme.breakpoints.down('sm')]:{
      width: '70%',
      textAlign: 'left',
      fontFamily: 'Nunito-Regular',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '33px',
      color: '#FFFFFF',
      paddingLeft: '21px',
      paddingTop: '100px',
      paddingBottom: '150px',
    }
  }
});

class Partner extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      params: {
        _start: 0,
        _limit: 9,
        page: 1,
      },
      total: 0,
      totalPagination: 0,
      pageSetting: {}
    }
  }

  setPagination(limit) {
  
    const totalPagination = limit.count / limit.limit;

    if (totalPagination > 1) {
      this.setState({total: ((limit.count / limit.limit).toFixed()), totalPagination: totalPagination});
    } else {
      this.setState({total: 1, totalPagination: 1});
    }
  }

  componentDidMount() {
    services.getPartners({
      _start: this.state.params._start,
      _limit: this.state.params._limit,
    }, (response, error) => {
      if (!error) {
        this.setState({data: response.data});
        this.setPagination({limit: this.state.params._limit, count: response.total.data})
      } else {
        console.log(error);
      }
    }),
    globalServices.getPageSetting((response,err) => {
      if(!err) {
        this.setState({pageSetting: response});
      }
    })
  }
  componentDidUpdate(prevProps,prevState) {
    if (this.state.params.page !== prevState.params.page) {
      services.getPartners({
        _start: this.state.params._start,
        _limit: this.state.params._limit,
      }, (response, error) => {
        if (!error) {
          this.setState({data: response.data});
          this.setPagination({limit: this.state.params._limit, count: response.total.data})
        } else {
          console.log(error);
        }
      })
    }
  }
  render() {
    const classes = this.props.classes;
    const title = this.state.pageSetting.PARTNER_TITLE;
    const description = this.state.pageSetting.PARTNER_DESCRIPTION;
    console.log(this.state.total)
    return (
      <div className={classes.background}>
        <Header />
        <div className={clsx(classes.container, 'relative text-center pb-10')}>
          <div className={clsx(classes.header, 'flex flex-col items-center relative')}>
            <span
              style={{
                fontFamily: 'Nunito-Regular',
                fontStyle: 'normal',
                fontWeight: 800,
                fontSize: '45px',
                lineHeight: '61px',
                color: '#FFFFFF',
              }}
            >
              {/* Partner Kami */}
              {title}
            </span>
            <p className="w-96"
              style={{
                fontFamily: 'Inter-Regular',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '24px',
                color: '#FFFFFF',
              }}
            >
              {description}
            </p>
          </div>
          <div className={classes.mobileHeader}>
            {title}
          </div>
          <div className={clsx(style.gridStyle, 'px-4 lg-0')}>
            <div>
              <div className="justify-center grid grid-cols-1 lg:grid-cols-3 grid-flow-row gap-x-2 gap-y-0 w-full xl:w-9/12 mx-auto px-auto">
                {this.state.data.map((data, index) => {
                  const image = getImages(data.logo.url);
                  return (
                    <div className="mb-3 flex flex-col shadow border rounded-xl h-auto lg:w-72" key={index} style={{ backgroundColor: '#FFFFFF' }}>
                      <img className="my-16 w-18 h-10 self-center" src={image} />
                      <div className="mx-8 text-justify">
                        <div style={{
                          fontFamily: 'Inter-Regular',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#444444',
                        }}>
                          {data.name}
                        </div>
                        <div className="text-center h-52"
                          style={{
                            fontFamily: 'Inter-Regular',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px',
                            lineHeight: '24px',
                            textAlign: 'justify',
                            color: '#444444',
                          }}
                        >
                          {data.description}
                        </div>
                        <a href={data.link} target="_blank" rel="noreferrer" className="mt-6 mb-11 flex flex-row justify-between">
                          <div style={{
                            fontFamily: 'Inter-Regular',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#003BC4',
                          }}>
                            SELENGKAPNYA
                          </div>
                          <img src={require('../../assets/icon/Group.png').default} />
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={style.background}></div>
        <div className="my-12 justify-center flex">
          <Pagination
            count={this?.state?.total}
            onChange={(event, page) => this.setState(prev => (
              page === 1 ? 
                {params: {...prev.params, _start: 0,page: page},}
              : page > 2 ?
                {params: {...prev.params, _start: (prev.params._limit * page)-1,page: page},}
              : 
                {params: {...prev.params, _start: prev.params._limit,page: page},}
            ))}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

export default withStyles(styles)(Partner);