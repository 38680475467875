import { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import get from 'lodash/get';

import Button from '../../../components/button';
import Input from '../../../components/input';
import Navbar from '../../../components/header';
import Footer from '../../../components/footer';

// Utils
import services from '../services';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 1465,
    backgroundImage: `url(${require('../../../assets/karir/peopleBackground.png').default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      backgroundImage: 'none',
    },
  },

  job: {
    paddingTop: 140,
    paddingBottom: 140,

    [theme.breakpoints.down('md')]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  jobLeftTitle: {
    fontFamily: 'Nunito-Regular',
    fontWeight: 800,
    fontSize: 45,
    lineHeight: '61px',
    color: '#fff'
  },
  jobLeftParagraf: {
    fontFamily: 'Inter-Regular',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    width: 313,
    color: '#fff',
    marginTop: 20,
  },
  jobBreadcrumb: {
    display: 'flex',

    "& li::after": {
      content: '>',
      display: 'block',
      width: 10,
      height: 10,
      color: '#003BC4',
    },
  },
}));

const ApplyJobSchema = Yup.object().shape({
  firstname: Yup.string().required('Nama depan tidak boleh kosong'),
  lastname: Yup.string().required('Nama belakang tidak boleh kosong'),
  email: Yup.string().email().required('Email tidak boleh kosong'),
  mobileNumber: Yup.string().required('Nomor kontak tidak boleh kosong'),
  lastEducation: Yup.string().required('Pendidikan terahir tidak boleh kosong'),
  department: Yup.string().required('Jurusan studi tidak boleh kosong'),
  institution: Yup.string().required('Instansi pendidikan tidak boleh kosong'),
  yearin: Yup.string().required('Tahun masuk tidak boleh kosong'),
  yearout: Yup.string().required('Tahun lulus tidak boleh kosong'),
  document: Yup.string().required('CV / Portofolio tidak boleh kosong'),
});

function ApplyJob() {
  const { id } = useParams();
  const [isMessage, setMessage] = useState({ open: false, message: null, severity: '' });
  const [response, setResponse] = useState();
  const [listEducation, setListEducation] = useState([]);
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      mobileNumber: '',
      lastEducation: '',
      institution: '',
      department: '',
      yearin: '',
      yearout: '',
      document: '',
    },
    validationSchema: ApplyJobSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      const formData = new FormData();
      const dataValue = JSON.stringify({
        firstName: values.firstname,
        lastName: values.lastname,
        phoneNumber: values.mobileNumber,
        lastEducation: values.lastEducation,
        instansiPendidikan: values.institution,
        jurusanStudi: values.department,
        tahunMasuk: values.yearin,
        tahunLulus: values.yearout,
        jobVacancy: id,
        email: values.email,
      });
      formData.append('data', dataValue);
      formData.append('files.document', values.document);
      setSubmitting(true)

      services.applyJob({
        data: formData,
      },
        {
          success: () => {
            setMessage({
              open: true,
              severity: 'success',
              message: 'Lamaran anda sudah terkirim',
            });
            setSubmitting(false);
            resetForm();
          },
          error: () => {
            setSubmitting(false);
            setMessage({
              open: true,
              severity: 'error',
              message: 'Internal Error 500',
            })
          },
        });
    }
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });

    services.getDetailJob({ id }, {
      success: (response) => {
        setResponse(response);
      },
      error: () => { },
    });

    services.getEducation((response, err) => {
      if (!err) {
        setListEducation(response);
      }
    });
  }, []);

  const { handleSubmit, getFieldProps, setFieldValue, touched, errors, isSubmitting } = formik;
  const jobId = get(response, 'id');
  const jobTitle = get(response, 'jobTitle');

  const breadcrumb = [
    {
      name: 'Career',
      href: '/karir',
    },
    {
      name: 'All Job',
      href: '/karir',
    },
    {
      name: jobTitle,
      href: `/karir/job/${jobId}`,
    },
    {
      name: 'Daftar',
    },
  ];

  return (
    <div>
      <Navbar />
      <div className={classes.root}>
        <div className={clsx(classes.job, 'flex w-10/12 lg:w-8/12 xl:w-10/12 mx-auto')}>
          <div className="w-1/2 hidden lg:block">
            <div className="flex flex-col">
              <span className={classes.jobLeftTitle}>
                Daftarkan <br />
                Dirimu!
              </span>
              <p className={classes.jobLeftParagraf}>
                Mari bergabung dengan AstraPay dalam misi mempermudah dan
                mempercepat transaksi digital masyarakat Indonesia.
              </p>
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <div className="flex flex-col">
              <ul className={clsx(classes.jobBreadcrumb, 'space-x-2')}>
                {breadcrumb.map((item) => {
                  if (item.href) {
                    return (
                      <li key={item.id}><Link to={item.href} className={clsx('font-inter text-primary-blue text-base font-normal')}>{item.name}</Link> &gt;</li>
                    )
                  }

                  return <li key={item.id} className={clsx('font-inter text-grey-2 text-base font-normal')}>{item.name}</li>
                })}
              </ul>

              <form className="mt-10 space-y-3" onSubmit={handleSubmit}>
                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Nama Depan</span>
                  <Input
                    {...getFieldProps('firstname')}
                    placeholder="Nama Depan"
                    type="text"
                    error={Boolean(touched.firstname && errors.firstname)}
                    errorText={touched.firstname && errors.firstname}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Nama Belakang</span>
                  <Input
                    {...getFieldProps('lastname')}
                    placeholder="Nama Belakang"
                    type="text"
                    error={Boolean(touched.lastname && errors.lastname)}
                    errorText={touched.lastname && errors.lastname}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Email</span>
                  <Input
                    {...getFieldProps('email')}
                    placeholder="Email"
                    type="email"
                    error={Boolean(touched.email && errors.email)}
                    errorText={touched.email && errors.email}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Nomor Kontak</span>
                  <Input
                    {...getFieldProps('mobileNumber')}
                    placeholder="Nomor Kontak"
                    type="tel"
                    error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                    errorText={touched.mobileNumber && errors.mobileNumber}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Pendidikan Terakhir</span>
                  <select
                    className="form-select w-full border boder-gray-300 px-4 py-2 rounded-md mt-2"
                    {...getFieldProps('lastEducation')}
                  >
                    <option value="">Pilih pendidikan terakhir</option>
                    {listEducation.map((education) => {
                      const value = get(education, 'id');
                      const label = get(education, 'name');

                      return (
                        <option value={value} key={value}>{label}</option>
                      )
                    })}
                  </select>
                  {Boolean(touched.lastEducation && errors.lastEducation) && (
                    <p className="mt-2 font-inter text-red-500 text-base font-light">
                      {touched.lastEducation && errors.lastEducation}
                    </p>
                  )}
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Instansi Pendidikan</span>
                  <Input
                    {...getFieldProps('institution')}
                    placeholder="Instansi Pendidikan"
                    type="text"
                    error={Boolean(touched.institution && errors.institution)}
                    errorText={touched.institution && errors.institution}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Jurusan Studi</span>
                  <Input
                    {...getFieldProps('department')}
                    placeholder="Jurusan Studi"
                    type="text"
                    error={Boolean(touched.department && errors.department)}
                    errorText={touched.department && errors.department}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Tahun Masuk</span>
                  <Input
                    placeholder="Tahun Masuk"
                    type="text"
                    {...getFieldProps('yearin')}
                    error={Boolean(touched.yearin && errors.yearin)}
                    errorText={touched.yearin && errors.yearin}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">Tahun Lulus</span>
                  <Input
                    placeholder="Tahun Lulus"
                    type="text"
                    {...getFieldProps('yearout')}
                    error={Boolean(touched.yearout && errors.yearout)}
                    errorText={touched.yearout && errors.yearout}
                  />
                </div>

                <div className="flex flex-col">
                  <span className="font-inter font-normal text-base text-dark-grey">CV atau Portofolio</span>
                  <Input
                    className="form-input px-4 py-2 rounded-md mt-2"
                    placeholder="Tahun Lulus"
                    type="file"
                    onChange={(event) => {
                      setFieldValue('document', event.currentTarget.files[0])
                    }}
                    error={Boolean(touched.document && errors.document)}
                    errorText={touched.document && errors.document}
                  />
                </div>

                <Collapse in={isMessage.open}>
                  <Alert
                    severity={isMessage.severity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setMessage({ open: false, message: null, severity: '' });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {isMessage.message}
                  </Alert>
                </Collapse>
                <Button isLoading={isSubmitting} disabled={isSubmitting} type="submit">
                  Unggah
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ApplyJob;