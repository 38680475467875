import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import checkIcon from '../../../components/icon/check.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    position: 'relative',
    backgroundColor: '#00DDC7',
    paddingTop: 70,
    paddingBottom: 70,
  },
  title: {
    fontFamily: 'Nunito-Regular',
    fontWeight: 800,
    fontSize: 45,
    lineHeight: '60px',
    color: '#003BC4',
    width: 650,
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '24px',
    },
  },
  image: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 615,

    [theme.breakpoints.down('md')]: {
      width: 211,
    },
  },
}));

function Keuntungan(props) {
  const { list } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="w-10/12 lg:w-8/12 mx-auto">
        <span className={classes.title}>
          Keuntungan Kerja di AstraPay
        </span>

        <ul className="space-y-5 mt-6 mb-40 lg:mb-0">
          {list.map((item, index) => {
            return (
              <li className="flex items-start lg:items-center space-x-3" key={index}>
                <img className="rounded-full w-5 h-5 lg:w-8 lg:h-8 xl:w-10 xl:h-10" src={checkIcon} />
                <p className="font-medium font-inter text-base md:text-lg lg:text-xl xl:text-xl 2xl:text-2xl text-primary-blue">
                  {item.detail}
                </p>
              </li>
            )
          })}
        </ul>
      </div>

      <img className={classes.image} src={require('../../../assets/banner/bannerKarir.png').default} />
    </div>
  );
}

Keuntungan.propTypes = {
  list: PropTypes.array,
};

Keuntungan.defaultProps = {
  list: [],
};

export default Keuntungan;