import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Animated } from "react-animated-css";
import clsx from 'clsx';
// import images from '../dummy/productImage';
import style from '../index.module.scss';

import ArrowRight from '../../../components/icon/arrowRight';

// Utils
import getImages from '../../../utils/getImages';

const useStyles = makeStyles(theme => createStyles({
  animatedTutorial: {
    height: 86,
    top: '10.8%',
    bottom: '20%',
    right: '18.7%',
    left: '36.4%',

    [theme.breakpoints.only('xs')]: {
      top: '8.5%',
      bottom: '20%',
      right: '26.2%',
      left: '28.6%',
    },

    [theme.breakpoints.only('sm')]: {
      top: '15%',
      bottom: '20%',
      right: '37%',
      left: '34%',
    },

    [theme.breakpoints.between('md', 'lg')]: {
      top: '8.5%',
      bottom: '20%',
      right: '18.7%',
      left: '36.4%',
    },

    [theme.breakpoints.only('lg')]: {
      top: '8.5%',
      bottom: '20%',
      right: '18.7%',
      left: '36.4%',
    },

    [theme.breakpoints.only('xl')]: {
      top: '10.8%',
      bottom: '20%',
      right: '18.7%',
      left: '36.4%',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      top: '8.5%',
      bottom: '20%',
      right: '36.2%',
      left: '37.8%',
    },

    [theme.breakpoints.only('md')]: {
      top: '8.5%',
      bottom: '20%',
      right: '18.6%',
      left: '36%',
    },
  },
  selectionTutorialItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.8px solid #003BC4',
    borderRadius: '50%',
    padding: 12,
    width: 18,
    height: 18,
    'box-sizing': 'border-box',

    "&:before": {
      content: 'halo',
      width: '40px',
      height: '40px',
      lineHeight: '40px',
      display: 'block',
      textAlign: 'center',
      margin: '0 auto 10px auto',
    },
  },
}));

function FeatureTutorial(props) {
  const { list } = props;
  const [active, setActive] = useState(1);

  const classes = useStyles();
  const classAnimated = clsx(classes.animatedTutorial, 'absolute z-10 2xl:mt-4');

  useEffect(() => {
    setActive(list?.[0]?.id);
  }, [list]);

  return (
    <div className="flex justify-evenly flex-col lg:flex-row">
      <div className="relative order-2 lg:order-1">
        <img className="2xl:mt-4 -ml-8 md:ml-32 lg:ml-0" src={require('../../../assets/image/Mockup pegang HP 1.png').default} alt="" />

        <div className={classAnimated}>
          {list.map((tutorial) => {
            const isActive = Number(tutorial.id) === active;
            const images = getImages(tutorial.image?.url);
            return (
              isActive && (
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} key={tutorial.id}>
                  <img src={images} key={tutorial.id} className="m-0 p-0 w-full" />
                </Animated>
              )
            )
          })}
        </div>
      </div>

      <div className="order-1 lg:order-2 mt-16 md:mt-8">
        <span
          className="hidden lg:block"
          style={{
            fontFamily: 'Inter-Regular',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            color: '#003BC4',
          }}
        >
          Cara Gampang Bayar Tagihan via AstraPay
        </span>

        <div className="hidden lg:block mt-5">
          {list.map((tutorial, index) => {
            const isActive = Number(tutorial.id) === active;
            const classItem = clsx('flex flex-col items-center', {
              [style.activeDot]: isActive,
              [style.inactiveDot]: !isActive,
            });

            const stepNumber = index + 1;
            const isLast = list.length === (index + 1);

            return (
              <div
                className="flex flex-row"
                role="button"
                onClick={() => setActive(Number(tutorial.id))}
                key={tutorial.id}
                style={{ height: 90 }}
              >
                <div className={classItem} key={tutorial.id} style={{ minWidth: 30, width: 30, minHeight: 30, height: 30 }}>
                  {stepNumber}
                  {!isLast && (
                    <div className="py-2">
                      <img src={require('../../../assets/icon/Arrow.svg').default} alt="" />
                    </div>
                  )}
                </div>
                <div
                  className="ml-6"
                  style={{
                    fontfamily: 'Inter-Regular',
                    fontstyle: 'normal',
                    fontweight: 'normal',
                    fontsize: '18px',
                    lineheight: '22px',
                    color: '#000000',
                  }}
                >
                  {tutorial.step}
                </div>
              </div>
            );
          })}
        </div>

        <div className="w-full flex justify-center lg:hidden">
          <ul className="flex items-center space-x-2">
            {list.map((tutorial, index) => {
              const classActive = clsx([classes.selectionTutorialItem], {
                'bg-primary-blue text-white': Number(tutorial.id) === active,
              });
              const stepNumber = index + 1;

              return (
                <div className="flex items-center space-x-2" key={tutorial.id}>
                  <li className={classActive} onClick={() => setActive(Number(tutorial.id))}>
                    {stepNumber}
                  </li>

                  {Number(stepNumber) !== list.length && (
                    <ArrowRight />
                  )}
                </div>
              )
            })}
          </ul>
        </div>

        <div className="w-full flex items-center justify-center px-3 my-2 lg:hidden">
          {list.map((tutorial) => {
            if (Number(tutorial.id) === active) {
              return (
                <span className="text-center">
                  {tutorial.step}
                </span>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

FeatureTutorial.propTypes = {
  list: PropTypes.array,
};

FeatureTutorial.defaultProps = {
  list: [],
};

export default FeatureTutorial;
