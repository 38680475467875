import { Component } from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

class Footer extends Component {
  static propTypes = {
    href: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <footer>
        <div className="hidden lg:block 2xl:container xl:mx-auto lg:mx-0 mx-12">
          <div className={style.footerWrapper}>
            <div className={style.footerContainer}>
              <div className={style.footerContent}>
                <img src={require('../../assets/logo/Footer - Logo .png').default} alt="" style={{ width: 115, height: 115 }} />

                <div className={clsx(style.listContainer, 'space-y-3')}>
                  <a href={'/product_feature'} className={style.list}>Produk & Fitur</a>
                  <a href={'/promo'} className={style.list}>Promo</a>
                  <a href={'/faq'} className={style.list}>FAQ</a>
                </div>

                <div className={clsx(style.listContainer, 'space-y-3')}>
                  <a href={'/help_center'} className={style.list}>Kontak Kami</a>
                  <a href={'/karir'} className={style.list}>Karir</a>
                  <a href={'/partner'} className={style.list}>Partner Kami</a>
                </div>

                <div className={clsx(style.listContainer, 'space-y-3')}>
                  <a href={'/terms_and_condition'} className={style.list}>Syarat & Ketentuan</a>
                  <a href={'/privacy_policy'} className={style.list}>Kebijakan Privasi</a>
                  <a href={'/blog'} className={style.list}>Blog</a>
                </div>

                <div className="flex xl:flex-row flex-col">
                  <div className={clsx(style.listContainer,'space-y-4')}>
                    <div className={style.listTitle}>Ikuti Kami</div>
                    <div className={style.socialMediaContainer}>
                      <img src={require('../../assets/icon/youtube 1.png').default} />
                      <a href={'https://www.youtube.com/channel/UCRi5YIVNuaY353xjsJzSIzQ'} className={style.socialMediaText}>AstraPay Indonesia</a>
                    </div>
                    <div className={style.socialMediaContainer}>
                      <img src={require('../../assets/icon/linkedin 1.png').default} />
                      <a href={'https://id.linkedin.com/company/astrapay'} className={style.socialMediaText}>PT Astra Digital Arta (AstraPay)</a>
                    </div>
                    <div className={style.socialMediaContainer}>
                      <img src={require('../../assets/icon/facebook 1.png').default} />
                      <a href={'https://www.facebook.com/astrapayID/'} className={style.socialMediaText}>AstraPay</a>
                    </div>
                  </div>
                </div>
                <div className="flex xl:flex-row flex-col">
                  <div className={clsx(style.listContainer,'space-y-3')}>
                    <div className={clsx(style.socialMediaContainer,'pt-6')}>
                      <img src={require('../../assets/icon/twitter 1.png').default} />
                      <a href={'https://twitter.com/AstrapayID?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'} className={style.socialMediaText}>@astrapayid</a>
                    </div>
                    <div className={style.socialMediaContainer}>
                      <img src={require('../../assets/icon/instagram 2 1.png').default} />
                      <a href={'https://www.instagram.com/astrapayid/?hl=id'} className={style.socialMediaText}>@astrapayid</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.footerBottomContainer}>
                <span className={style.footerBottomText}>PT Astra Digital Arta ASTRAPAY 2021 © All Rights Reserved</span>
              </div>

            </div>
          </div>
        </div>

        <div className="w-full px-4 py-10 block lg:hidden">
          <div className="flex items-center my-2">
            <img src={require('../../assets/logo/Footer - Logo .png').default} alt="" style={{ width: 55, height: 55 }} />

            <div className="ml-5">
              <span className={style.listTitle}>IKUTI KAMI</span>
              <ul className="flex items-center space-x-2 mt-2">
                <li>
                  <a rel="noreferrer" href="https://www.youtube.com/channel/UCRi5YIVNuaY353xjsJzSIzQ" target="_blank">
                    <img src={require('../../assets/icon/youtube 1.png').default} />
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" href="https://id.linkedin.com/company/astrapay" target="_blank">
                    <img src={require('../../assets/icon/linkedin 1.png').default} />
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" href="https://www.facebook.com/astrapayID/" target="_blank">
                    <img src={require('../../assets/icon/facebook 1.png').default} />
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" href="https://twitter.com/AstrapayID?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                    <img src={require('../../assets/icon/twitter 1.png').default} target="_blank" />
                  </a>
                </li>
                <li>
                  <a rel="noreferrer" href="https://www.instagram.com/astrapayid/?hl=id" target="_blank">
                    <img src={require('../../assets/icon/instagram 2 1.png').default} />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <hr className="w-full my-5" style={{ borderColor: '#999' }} />

          {/* <div className="flex items-start px-auto justify-center">
            <ul className="mx-auto">
              <li>
                <a href="/product_feature" className={style['list-mobile']}>Produk & Fitur</a>
              </li>
              <li>
                <a href="/promo" className={style['list-mobile']}>Promo</a>
              </li>
              <li>
                <a href="/faq" className={style['list-mobile']}>FAQ</a>
              </li>
              <li>
                <a href={'/help_center'} className={style['list-mobile']}>Kontak Kami</a>
              </li>
              <li>
                <a href={'/karir'} className={style['list-mobile']}>Karir</a>
              </li>
            </ul>

            <ul className="mx-auto">
              <li>
                <a href={'/partner'} className={style['list-mobile']}>Partner Kami</a>
              </li>
              <li>
                <a href={'/terms_and_condition'} className={style['list-mobile']}>Syarat & Ketentuan</a>
              </li>
              <li>
                <a href={'/privacy_policy'} className={style['list-mobile']}>Kebijakan Privasi</a>
              </li>
              <li>
                <a href={'/blog'} className={style['list-mobile']}>Blog</a>
              </li>
            </ul>
          </div> */}
          <div className="flex items-start">
            <ul className="flex-1">
              <li>
                <a href="/product_feature" className={style.list}>Produk & Fitur</a>
              </li>
              <li>
                <a href="/promo" className={style.list}>Promo</a>
              </li>
              <li>
                <a href="/faq" className={style.list}>FAQ</a>
              </li>
            </ul>

            <ul className="flex-1">
              <li>
                <a href={'/help_center'} className={style.list}>Kontak Kami</a>
              </li>
              <li>
                <a href={'/karir'} className={style.list}>Karir</a>
              </li>
              <li>
                <a href={'/partner'} className={style.list}>Partner Kami</a>
              </li>
            </ul>

            <ul className="flex-1">
              <li>
                <a href={'/terms_and_condition'} className={style.list}>Syarat & Ketentuan</a>
              </li>
              <li>
                <a href={'/privacy_policy'} className={style.list}>Kebijakan Privasi</a>
              </li>
              <li>
                <a href={'/blog'} className={style.list}>Blog</a>
              </li>
            </ul>
          </div>
        </div>

      </footer>
    );
  }
}

export default withRouter(Footer);
