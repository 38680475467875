export const api = 'https://apiastrapay-demo.kotadigivice.com';
export const zendeskToken = 'YTYyNjI1NzRAZ21haWwuY29tL3Rva2VuOjZPTzU2N3FMQm1zeTRVZXlnQWlzVzltNHdqcHdPZVRvcTR6Qm5HaFI=';
export const reCaptcha = '6LcEyhsbAAAAAG0yIP79zNTm607EzD3rF9i7d0_E';

const endpoint = {
  // Page setting
  pageSetting: `${api}/page-settings`,

  // Homepage
  homepage: `${api}/homepage`,

  // Product
  product: `${api}/products`,
  categoryProduct: `${api}/category-products`,

  // Karir
  karir: `${api}/karir-page`,

  // Promo
  promo: `${api}/promos`,
  categoryPromo: `${api}/category-promos`,

  // Job
  job: `${api}/job-vacancies`,
  detailJob: `${api}/job-vacancies/`,
  applyJob: `${api}/job-applications`,
  departement: `${api}/departments`,
  education: `${api}/last-educations`,
  workType: `${api}/work-types`,

  //Partner
  partner: `${api}/partners`,

  //Faq
  faq: `${api}/faqs`,
  categoryFaq: `${api}/category-faqs`,
  
  // Blog
  blog: `${api}/blogs`,
  categoryBlog: `${api}/category-blogs`,
  articlePopular: `${api}/blogs?_sort=view_count:desc&_limit=4`,

  // Helpcenter
  ticket: `${api}/tickets`,

  // TnC
  tnc: `${api}/ketentuan-penggunaan`,

  // Privacy Policy
  privacy: `${api}/kebijakan-privasi`,
};

export default endpoint;