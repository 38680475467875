import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import style from './index.module.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropUp';
import Mail from '@material-ui/icons/MailOutlineOutlined';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
// import Pagination from '@material-ui/lab/Pagination';
import Select from '../../components/select';
import usePagination from '../../utils/usePagination';
import InputSearch from '../../components/input/Search';
import { useFormik } from 'formik';
import Pagination from '../../components/pagination';
// Utils
import services from './services';
import getImages from '../../utils/getImages';
// import axios from 'axios';
import SvgColor from 'react-svg-color'

import { useLocation } from "react-router-dom";
import globalServices from '../../services';

const styles = (theme) => ({
  titleText: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: '0rem',
      marginTop: '1rem',
      fontFamily: 'Nunito-Regular',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '33px',
    },
    [theme.breakpoints.only('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      left: '1%',
      marginTop: '1rem',
    }
  },
  BannerTextMobile: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20px',
      paddingRight: '100px',
      paddingLeft: '50px',
      textAlign: 'left',
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px',
      paddingBottom: '10px'
    }
  },
  imageBanner: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      visibility: 'hidden',
    }
  },
  faqTitle: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#8B8B8B',
  },
  search: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '16rem',
      marginTop: '2rem'
    },
    [theme.breakpoints.only('lg')]: {
      left: '15%',
    }
  },
  mobileCategoryText: {

    [theme.breakpoints.down('md')] : {
      fontfamily: 'Inter-Regular',
      fontstyle: 'normal',
      fontweight: '300',
      fontsize: '12px',
      lineheight: '16px',
      color: '#444444',
    },
  },
  contentMobile: {
    [theme.breakpoints.down('md')] : {
      width: '100%'
    },
  },
  faqDesc: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1A1A1A',
  },

  expandedPanel: {
    color: '#003BC4'
  },
  formButton: {
    "&::placeholder": {
      color: '#009FFE',
    },
  }
});

function FAQ(props) {

  const {classes} = props;
  const [expanded, setExpanded] = React.useState('');
  const [listFaq, setListFaq] = React.useState({
    data: [],
  })
  const [listCategoryFaq, setListCategoryFaq] = React.useState({
    data: [],
  })
  const [total, setPagination] = usePagination();
  const [params, setParams] = React.useState({
    page: 1,
    _limit: 5,
    category: null,
  });
  const [pageSetting, setPageSetting] = React.useState({});

  // const [url, setUrl] = React.useState('https://apiastrapay-demo.kotadigivice.com/faqs?category.id=1')
  const [selectedFeature, setSelectedFeature] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onSelectFeature = (index) => {
    // if (selectedFeature===0) {
      setSelectedFeature(index)
    // }
  }

  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      setParams({ page: 1, _q: values.search, _limit: 9, category: null });
      // setUrl(`https://apiastrapay-demo.kotadigivice.com/faqs?_q=${params._q}&_limit=${9}`)
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  React.useEffect(() => {
    const { _limit, category } = params;

    services.getFaq({
      _limit: _limit,
      _q: params._q,
      ['category.id']: category,
    }, {
      success: (response) => {
        setListFaq({data: response.data});
        setPagination(9, response.total);
      },
      error: (err) => {
        console.log(err)
      }
    });

    services.getCategoryFaq({
      success: (data) => {
        setListCategoryFaq({data: data.data})
      },
      error: (err) => {
        console.log(err)
      },
    }),

    location.state ? setExpanded(`${location.state.faqChange}`) : setExpanded('panel0');
    globalServices.getPageSetting((response, err) => {
      if (!err) {
        setPageSetting(response);
      }
    })
  }, [params])

  // React.useEffect(() => {
  //   axios.get(url)
  //   .then(data => setListFaq({data: data.data}))
  //   .catch(err => console.log(err))
  // }, [url])
  const title = (pageSetting.FAQ_TITLE)
    return (
      <div className="overflow-hidden">
        <Header />
        <div className="w-screen 2xl:h-84 md:h-64 h-52 relative" style={{background: 'radial-gradient(63.88% 63.88% at 50% 50%, #0045E5 0%, #003BC4 100%)'}}>
          <div className="2xl:ml-96 2xl:pt-20 lg:pt-7">
            <div className={clsx(classes.titleText,style.titleText)}>
              <div className={clsx(classes.BannerTextMobile,"lg:w-full md:ml-32 lg:ml-0 md:w-11/12 sm:text-left sm:w-1/2 md:text-center")}>
                {/* Temukan seluruh informasi seputar AstraPay sekarang! */}
                {title}
              </div>
              <div className={style.searchButton}>
                <form className={clsx(classes.search,"relative flex items-center w-1/2 mt-14 md:mt-5 2xl:ml-56 lg:ml-40")} onSubmit={handleSubmit}>
                  <input
                    className={clsx(classes.formButton,"transition font-hind outline-none rounded-3xl duration-300 ease h-10 w-full py-2 pl-4 pr-14 text-sm focus:outline-none")}
                    type="text"
                    name="search"
                    placeholder="Ketik untuk Mencari"
                    {...getFieldProps('search')}
                    style={{borderRadius: '25px', backgroundColor: '#0045E5', color: '#009FFE,', width: '100%', border: '1px solid #FFFFFF'}}
                  />
                  <button
                    type="submit"
                    className="absolute right-1 top-1 mb-3 px-2 focus:outline-none h-8 rounded-3xl"
                    style={{backgroundColor: '#FFFFFF'}}
                  >
                    <img src={require('../../assets/icon/Group 139.png').default} style={{color: '#0045E5'}} />
                  </button>
                </form>
                <div className="flex justify-center items-center w-full lg:hidden pt-8 sm:pt-20">
                  <img src={require('../../assets/icon/faq/Group 145.png').default} className="w-7/12 h-auto"/>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.imageBanner}>
            <img src={require('../../assets/icon/Group 140.png').default} className="flex items-center 2xl:ml-64 top-0 2xl:w-56 absolute md:w-40 md:-bottom-4 md:ml-40 lg:-left-32"/>
            <img src={require('../../assets/icon/Group 141.png').default} className="flex items-center 2xl:ml-64 top-0 2xl:w-48 2xl:right-64 md:w-30 absolute md:-bottom-4 md:ml-40 md:right-32 lg:right-8"/>
          </div>
        </div>

        <form className="lg:hidden items-center mt-7 mx-6" onSubmit={handleSubmit}>
          <InputSearch
            placeholder="Ketik untuk mencari"
            name="search"
            {...getFieldProps('search')}
          />
        </form>
        
        <div className="mx-8 mt-5 lg:hidden">
          <div className={classes.mobileCategoryText}>
            Kategori FAQ
          </div>
          {/* <select
            className="form-select w-full px-3 py-2 font-semibold text-primary-blue rounded-md"
            style={{ border: '0.5px solid #999999;' }}
            // onChange={(e) => setUrl(`https://apiastrapay-demo.kotadigivice.com/faqs?category.id=${Number(e.target.value)+1}`)}
            onChange={(e) => setParams(prev => ({ ...prev, category: e.target.value }))}
          >
            {listCategoryFaq?.data?.map((article, index) => {
              return (
                <option value={index} key={index}>
                  {article.name}
                </option>
              )
            })}
          </select> */}
          <div className="py-2">
            <Select items={listCategoryFaq?.data} onChange={(e) => setParams(prev => (Number(e.target.value) === 0 ? { ...prev, category: null } : { ...prev, category: `${Number(e.target.value)+1}` }))} options={null} />
          </div>
        </div>

        <div className="flex w-full">
          <div className={clsx(style.category, 'hidden lg:flex')}>
            <div className="text-center pt-20 xl:w-full xl:mr-16 h-full lg:w-96" style={{backgroundColor: '#F7F7F7'}}>
              <div className="inline-block text-left">
                
                {listCategoryFaq?.data?.map((article,index) => {
                  const image = getImages(article.icon.url)
                  return (
                    <div key={index} className="w-46 ml-8 xl:w-96" 
                      // onClick={() => setUrl(`https://apiastrapay-demo.kotadigivice.com/faqs?category.id=${article.id}`)}
                      onClick={() => setParams(prev => ({ ...prev, category: article.id }))}
                    >
                      <div className="flex flex-row items-center">
                        <div>
                          <SvgColor svg={image} onClick={() => onSelectFeature(index)} colors={selectedFeature === index ? ['#0045E5','#0045E5','#0045E5','#0045E5','#0045E5' ,'#0045E5'] : [null]} />
                        </div>
                        <div onClick={() => onSelectFeature(index)} className={selectedFeature === index ? style.inter600LeftBefore : style.inter600}>
                          {article.name}
                        </div>
                      </div>
                      <div onClick={() => onSelectFeature(index)} 
                        className={selectedFeature === index ? style.borderTextAfter : null }
                        {...(article.name === 'Promo' && {style:{width: '5%'}})}
                      ></div>
                    </div>
                )})}
                <div className={style.border}></div>
                <div className={style.interNormal}>Tidak menemukan yang kamu cari? </div>
                <Link className={clsx(style.buttonCall, 'mx-20 hover:text-white my-3')} to="/help_center">
                  <Mail className="mr-2" />
                  Hubungi Kami
                </Link>
              </div>
            </div>
          </div>
          <div className={clsx(classes.contentMobile,"flex flex-col mt-10 h-auto items-center w-3/4 xl:w-8/12")}>
            {listFaq?.data?.map((blog,index) => (
              <>
                <div className="flex-col" key={index}>
                  
                  <div className="mx-10 xl:-ml-10">
                    <Accordion square expanded={expanded === 'panel' + blog.id} onChange={handleChange('panel' + blog.id)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon style={expanded === 'panel' + blog.id ? { color: '#003BC4' } : null} />} aria-controls="panel1d-content" id="panel1d-header" classes={{expanded: classes.expandedPanel}}>
                        <div className={expanded === 'panel' + blog.id ? clsx(classes.faqTitle, classes.expandedPanel) : classes.faqTitle}>{blog.title}</div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.faqDesc} dangerouslySetInnerHTML={{ __html: blog.description}}></div>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                </div>
              </>
            ))}

            
            <div className="my-12 lg:32">
              {/* <Pagination
                count={total}
                onChange={(event, page) => setParams(prev => ({ ...prev, _start: prev._limit * page }))}
                color="primary"
              /> */}
              <Pagination
                count={total}
                onChange={(event, page) => setParams(prev => ({ ...prev, _start: prev._limit * page }))}
                
              />
            </div>
          </div>
        </div>

          


        <Footer />
      </div>
    )
  }

  FAQ.propTypes = {
    classes: PropTypes.object.isRequired,
    faqChange: PropTypes.element,
  };

  export default withStyles(styles)(FAQ);
