/* eslint-disable no-undef */
const promo = [
  {
    'image': require('../../../assets/image/add - promo referral - 1000x563 1.png').default,
    'title1': 'Bayar PLN',
    'title2': 'Dapat Promo!',
    'date': 'Periode 5 Maret - 5 April 2021',
  },
  {
    'image': require('../../../assets/image/AP Promo Referal - 1000x563 2.png').default,
    'title1': 'Bayar PLN',
    'title2': 'Dapat Promo!',
    'date': 'Periode 5 Maret - 5 April 2021',
  },
  {
    'image': require('../../../assets/image/Promo PDAM - 1000x563 1.png').default,
    'title1': 'Bayar PDAM',
    'title2': 'Dapat Promo!',
    'date': 'Periode 5 Maret - 5 April 2021',
  },
  {
    'image': require('../../../assets/image/add - promo referral - 1000x563 1.png').default,
    'title1': 'Bayar PLN',
    'title2': 'Dapat Promo!',
    'date': 'Periode 5 Maret - 5 April 2021',
  },
  {
    'image': require('../../../assets/image/AP Promo Referal - 1000x563 2.png').default,
    'title1': 'Bayar PLN',
    'title2': 'Dapat Promo!',
    'date': 'Periode 5 Maret - 5 April 2021',
  },
  {
    'image': require('../../../assets/image/Promo PDAM - 1000x563 1.png').default,
    'title1': 'Bayar PDAM',
    'title2': 'Dapat Promo!',
    'date': 'Periode 5 Maret - 5 April 2021',
  },
  {
    'image': require('../../../assets/image/add - promo referral - 1000x563 1.png').default,
    'title1': 'Bayar PLN',
    'title2': 'Dapat Promo!',
    'date': 'Periode 5 Maret - 5 April 2021',
  },
]

export default promo;