import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Slick from 'react-slick';

const useStyles = makeStyles((theme) => ({
  root: {},
  dots: {
    bottom: -40,

    "& li.slick-active button::before": {
      color: '#999999',
    },

    "& li": {

      "& button::before": {
        fontSize: theme.typography.pxToRem(10),
        color: "#E9E9E9",
        opacity: 0.5,
      },
    }
  }
}));


const PrevArrow = (props) => {
  const { onClick, style } = props;

  return (
    <button onClick={onClick} style={style} className="rounded-full h-8 w-8 absolute left-0 top-0 bottom-0 my-auto ml-6 focus:outline-none z-10">
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3333 7.16675L0.999921 7.16675" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.83325 13L0.999921 7.16667L6.83325 1.33333" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};


const NextArrow = (props) => {
  const { onClick, style } = props;

  return (
    <button onClick={onClick} style={style} className="rounded-full h-8 w-8 absolute right-0 top-0 bottom-0 my-auto mr-6 focus:outline-none z-10">
      <svg className="transform rotate-180" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3333 7.16675L0.999921 7.16675" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.83325 13L0.999921 7.16667L6.83325 1.33333" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}

NextArrow.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
};


function Carousel(props) {
  const { className, children, innerRef, ...other } = props;
  const classes = useStyles();
  
  const classContainer = clsx(classes.root);

  return (
    <div className={classContainer}>
      <Slick
        className={className}
        dotsClass={clsx('slick-dots', classes.dots)}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        ref={innerRef}
        {...other}
      >
        {children}
      </Slick>
    </div>
  );
}

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  innerRef: PropTypes.any,
};

export default Carousel;