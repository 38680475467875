import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: '11px',
    // width: '326px',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

function PromoCard(props) {
  const { className, url, title, date, image } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className, 'shadow border')}>
      <img
        className="w-full"
        src={image}
        style={{
          borderTopLeftRadius: '11px',
          borderTopRightRadius: '11px',
        }}
      />

      <div className="ml-7 mt-3">
        <div style={{
          fontFamily: 'Inter-Regular',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          color: '#444444',
        }}>
          {title}
        </div>
        <div className="mt-1.5" style={{
          fontFamily: 'Inter-Regular',
          fontStyle: 'normal',
          fontSize: '12px',
          lineHeight: '15px',
        }}>
          {date}
        </div>
        <Link
          to={url}
          className="flex flex-row justify-between mr-3 my-3"
        >
          <div style={{
            fontFamily: 'Inter-Regular',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '15px',
            color: '#003BC4',
          }}>
            SELENGKAPNYA
            </div>
          <img src={require('../../assets/icon/Group.png').default} />
        </Link>
      </div>
    </div>
  )
}

PromoCard.propTypes = {
  className: PropTypes.string,
  history: PropTypes.any,
  image: PropTypes.any,
  title: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
}

export default withRouter(PromoCard)