import Header from '../../components/header';
import Footer from '../../components/footer';
// import PropTypes from 'prop-types';
import clsx from 'clsx';
import Pagination from '../../components/pagination';
import { makeStyles } from '@material-ui/core/styles';
import PromoCard from '../../components/promoCard';
import InputSearch from '../../components/input/Search';
import usePagination from '../../utils/usePagination';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import Loading from '../../components/loading';
import Select from '../../components/select';

import globalServices from '../../services';
import services from './services';
import getImages from '../../utils/getImages';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#EF3749',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '80% -10%',
    height: 500,

    [theme.breakpoints.down('md')]: {
      height: 265,
      backgroundSize: 'auto 60%',
      backgroundPosition: '80% bottom',
    },
  },
  headerText: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '70px',
    lineHeight: '86px',
    color: '#FFFFFF',

    [theme.breakpoints.only('md')]: {
      fontSize: 32,
      lineHeight: '32px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  container: {
    backgroundColor: '#E5E5E5',

    [theme.breakpoints.down('md')]: {
      backgroundColor: '#fff',
    },
  },
  tabTitle: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    whiteSpace: 'no-warp',
  },
  image: {
    display: 'block',
    position: 'absolute',
    right: 0,
    top: 100,
    marginRight: '10%',
    zIndex: 10,

    [theme.breakpoints.down('sm')]: {
      width: 180,
      top: 110,
      marginRight: '5%',
    },

    [theme.breakpoints.only('md')]: {
      width: 300,
      top: 5,
      marginRight: '8%',
    },
  },
  banner: {
    backgroundImage: `url('${require('../../assets/banner/bannerPromo.png').default}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '80% 50px',
    backgroundColor: '#00DDC7',
    width: '100%',
    minHeight: 350,

    [theme.breakpoints.only('sm')]: {
      backgroundSize: '50%',
      backgroundPosition: '85% 100px',
    },

    [theme.breakpoints.down('xs')]: {
      backgroundSize: 'auto 40%',
      backgroundPosition: '11rem 65%',
    },
  },
  bannerTitle: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '39px',
    lineHeight: '53px',
    color: '#003BC4',

    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
      lineHeight: '33px',
      paddingLeft: '12%',
    },
  },
  searchInput: {
    maxWidth: 300,
  },
   iconAppSize: {
    height: 'auto',

    [theme.breakpoints.only('sm')]: {
      height: 40,
    },

    [theme.breakpoints.up('md')]: {
      height: '2.5rem',
    },

    [theme.breakpoints.down('xs')]: {
      height: 29
    }
  },
}));

function Promo() {
  const [pageSetting, setPageSetting] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [listPromo, setListPromo] = useState({
    data: [],
    total: 0,
  });

  const [params, setParams] = useState({
    page: 1,
    _q: null,
    _limit: 9,
    category: null,
  });
  const [total, setPagination] = usePagination();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: (values) => {
      setParams(prev => ({ ...prev, page: 1, _q: values.search, _limit: 9 }));
    },
  });

  useEffect(() => {
    const { _limit, category } = params;
    setIsLoading(true)
    services.getPromo({
      _limit,
      _q: params._q,
      ...(category) && { ['category.id']: parseInt(category) },
    }, {
      success: (response) => {
        setIsLoading(false)
        setListPromo(response);
        setPagination(9, response.total);
      },
      error: () => { }
    });

    globalServices.getPageSetting((response, err) => {
      if (!err) {
        setPageSetting(response);
      }
    })
  }, [params]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    services.getCategoryPromo({
      success: (response) => {
        setListCategory(response);
      },
      error: () => { }
    })
  }, []);

  const { handleSubmit, getFieldProps, resetForm } = formik;

  const gridCount = listPromo.data.length < 3 ? `lg:grid-cols-${listPromo.total}` : 'grid-cols-1 lg:grid-cols-3';
  const title = get(pageSetting, 'PROMO_TITLE');
  const description = get(pageSetting, 'PROMO_DESCRIPTION');

  return (
    <>
      <Header />
      <div className={clsx(classes.header, 'flex flex-row relative overflow-visible')}>
        <div className="flex flex-col justify-start lg:justify-center w-11/12 mx-auto xl:w-auto xl:mx-0 lg:w-8/12">
          <div className="xl:ml-64 2xl:ml-60 mt-5 lg:mt-0">
            <span className={classes.headerText}>
              {title}
            </span>
            <div className="mt-7 w-2/3"
              style={{
                fontFamily: 'Inter-Regular',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#FFFFFF',
              }}
            >
              {description}
            </div>
            <form className="relative hidden lg:flex mt-14" onSubmit={handleSubmit}>
              <InputSearch
                classContainer={clsx(classes.searchInput)}
                placeholder="Ketik untuk mencari"
                name="search"
                onClose={() => {
                  resetForm();
                  setParams(prev => ({ ...prev, _q: null }));
                }}
                {...getFieldProps('search')}
              />
            </form>
          </div>
        </div>
        <img src={require('../../assets/promo/bannerPromo.png').default} className={classes.image} />
      </div>

      <div className={clsx(classes.container, 'flex flex-col')}>
        <form className="relative flex lg:hidden items-center w-full mt-14 px-4" onSubmit={handleSubmit}>
          <InputSearch
            placeholder="Ketik untuk mencari"
            name="search"
            onClose={() => {
              resetForm();
              setParams(prev => ({ ...prev, _q: null }));
            }}
            {...getFieldProps('search')}
          />
        </form>

        <div className="flex-row overflow-x-auto justify-center my-20 hidden lg:flex space-x-8">
          {[{ id: null, name: 'Semua Promo' }, ...listCategory].map((itemCategory, index) => {
            const isActive = params.category === itemCategory.id;
            const classTabActive = clsx({
              [classes.tabTitle + ' text-primary-blue']: isActive,
              [classes.tabTitle + ' text-grey-2']: !isActive,
            });

            return (
              <div
                key={index}
                className={classTabActive}
                role="button"
                onClick={() => setParams(prev => ({ ...prev, category: itemCategory.id }))}
              >
                {itemCategory.name}
                {isActive && (
                  <div style={{ height: 5 }} className="bg-primary-blue w-12"></div>
                )}
              </div>
            )
          })}
        </div>


        <div className="w-full px-4 my-4 block lg:hidden">
          {/* <select
            onChange={event => setParams(prev => ({ ...prev, category: event.target.value }))}
            className="form-select w-full px-3 py-2 font-semibold text-primary-blue rounded-md"
            style={{ border: '0.5px solid #999999;' }}
          >
            {[{ id: '', name: 'Semua Promo' }, ...listCategory].map((itemCategory, index) => {
              return (
                <option value={itemCategory.id} key={index}>
                  {itemCategory.name}
                </option>
              )
            })}
          </select> */}
          <div className="py-2">
            <Select items={[{ id: '', name: 'Semua Promo' }, ...listCategory]} onChange={event => setParams(prev => event.target.value === "0" ? ({ ...prev, category: null }) : ({ ...prev, category: event.target.value }))} options={null} />
          </div>
        </div>

        <div className="w-full flex justify-center flex-col items-center my-3 lg:my-0" style={{ minHeight: 300 }}>
          <Loading className="mb-10" isLoading={isLoading} isEmpty={listPromo.data.length === 0}>
            <div className={clsx(gridCount, 'w-11/12 lg:w-auto grid gap-4')}>
              {listPromo.data.map((itemPromo, index) => {
                const url = `/promo/${itemPromo.id}`;
                const title = get(itemPromo, 'title');
                const image = getImages(get(itemPromo, 'image.url'));
                const startDate = get(itemPromo, 'startDate');
                const endDate = get(itemPromo, 'endDate');
                const textDate = `Periode ${moment(startDate).format('D MMMM')} - ${moment(endDate).format('D MMMM YYYY')}`;

                return (
                  <PromoCard
                    key={index}
                    url={url}
                    image={image}
                    title={title}
                    date={textDate}
                  />
                )
              })}
            </div>
          </Loading>

          {!isLoading && listPromo.data.length !== 0 && (
            <div className="my-20 flex justify-center">
              <Pagination
                count={total}
                onChange={(event, page) => setParams(prev => ({ ...prev, _skip: page * 5 }))}
              />
            </div>
          )}
        </div>
      </div>

      <div className={clsx(classes.banner, 'flex flex-col lg:justify-center justify-start')}>
        <div className="sm:w-full sm:mx-auto md:w-full xl:w-8/12 flex flex-col lg:pt-0 pt-16">
          <p className={classes.bannerTitle}>
            Temukan promo menarik <br />
            di AstraPay sekarang!
          </p>

          <div className="pl-10 md:pl-24 xl:pl-0 flex flex-col lg:flex-row items-start lg:items-center lg:justify-start space-x-0 lg:space-x-3 space-y-2 lg:space-y-0 mt-5 lg:mt-5">
            <a className="mr-3" href="https://astrapay.onelink.me/9OXk/astrapaydownloadios">
              <img className={clsx("w-auto h-10 lg:h-10",classes.iconAppSize)} src={require('../../assets/banner/Badge Appstore.png').default} alt="" />
            </a>
            <a href="https://astrapay.onelink.me/8z2t/fc8b7d5f">
              <img className={clsx("w-auto h-10 lg:h-10",classes.iconAppSize)} src={require('../../assets/banner/Badge Google Play.png').default} alt="" ></img>
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

Promo.propTypes = {}

export default Promo;