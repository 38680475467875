import Navbar from '../../components/header';
import Footer from '../../components/footer';

export default function NotFound() {
  return (
    <div>
      <Navbar />
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col space-y-5 items-center">
          <img src={require('../../assets/banner/nodata.svg').default} className="w-40 h-40 object-contain" />
          <div className="flex flex-col items-center">
            <p className="font-inter text-3xl font-semibold text-dark-grey">
              Page not found
            </p>
            <p className="font-inter text-lg font-semibold text-dark-grey">
              Sorry we {"can't"} find your page
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}