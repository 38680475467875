import { Component } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import PromoCard from '../../components/promoCard';
import style from './index.module.scss';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
// import { Pagination } from '@material-ui/lab';
import Pagination from '../../components/pagination';
import get from 'lodash/get';
import moment from 'moment';

// Dummy
import promo from './dummy/promo';

// Services
import globalServices from '../../services';
import services from './services';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeatureHeader from './components/FeatureHeader';
import FeatureProduct from './components/FeatureProduct';
import Carousel from '../../components/carousel';
import getImages from '../../utils/getImages';

const withStyled = withStyles((theme) => ({
  root: {
    display: 'block'
  },
  headerContainer: {
    background: 'radial-gradient(63.88% 63.88% at 50% 50%, #0045E5 0%, #003BC4 100%)',
  },
  header: {
    backgroundImage: `url('${require('../../assets/banner/hero.png').default}')`,
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 675px',
    height: 680,
    paddingLeft: '10%',
    paddingRight: '10%',

    [theme.breakpoints.only('md')]: {
      backgroundImage: `url('${require('../../assets/banner/hero.png').default}')`,
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 300px',
      height: 360,
    },

    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url('${require('../../assets/banner/heroMobile.png').default}')`,
      backgroundPosition: 'bottom center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '270px 250px',
      height: 360,
      paddingLeft: '5%',
      paddingRight: '5%',
    },

    [theme.breakpoints.only('lg')]: {
      paddingLeft: '5%',
      paddingRight: '10%',
    },
  },
  headerTitle: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '70px',
    lineHeight: '86px',
    color: '#FFFFFF',
    width: 569,
    marginTop: 0,

    [theme.breakpoints.only('lg')]: {
      fontSize: '65px',
    },

    [theme.breakpoints.only('md')]: {
      fontSize: '30px',
      lineHeight: '42px',
      width: '60%',
      marginTop: 62
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
      width: '90%',
      marginTop: 62
    },
  },
  headerDescription: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    marginTop: 10,

    [theme.breakpoints.only('lg')]: {
      fontSize: '14px',
    },

    [theme.breakpoints.only('md')]: {
      fontSize: '14px',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerButton: {
    backgroundColor: '#00DDC7',
    color: '#003BC4',
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: 17.5,
    paddingTop: 10,
    paddingLeft: 40,
    paddingBottom: 10,
    paddingRight: 40,

    '&:hover': {
      color: '#003BC4',
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  banner: {
    height: 365,
    backgroundColor: '#EF3749',

    [theme.breakpoints.down('sm')]: {
      height: 240,
    },
  },
  bannerText: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '39px',
    lineHeight: '52px',
    color: '#FFFFFF',
    width: 365,

    [theme.breakpoints.only('md')]: {
      fontSize: '22px',
      lineHeight: '32px',
    },

    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Nunito-Regular',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '18px',
      lineHeight: '24px',
      color: '#FFFFFF',
      width: '80%',
    }
  },
  bannerImage: {
    backgroundImage: `url('${require('../../assets/banner/Banner 3.png').default}')`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    paddingLeft: '12%',

    [theme.breakpoints.only('md')]: {
      backgroundSize: 'auto 60%',
      backgroundPosition: 'bottom',
    },

    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'auto 60%',
      backgroundPosition: '-9rem bottom',
      // paddingLeft: '7%',
    },
  },
  bannerVideo: {
    display: 'block',
    position: 'absolute',
    right: 238,
    bottom: 0,

    [theme.breakpoints.down('md')]: {
      display: 'none',
      right: 0,
      left: 0,
    }
  },
  video: {
    display: 'block',
    borderRadius: '30px 30px 0px 0px',
    width: 377,
    height: 'auto',

    [theme.breakpoints.down('md')]: {
      display: 'none',
      borderRadius: '5px 5px 0px 0px',
      width: 110,
    },
  },
  faqTitle: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '45px',
    lineHeight: '61px',
    color: '#FFFFFF',

    [theme.breakpoints.only('md')]: {
      fontSize: 24,
      lineHeight: '26px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '24px',
    },
  },
  faqItem: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  dots: {
    bottom: -50,
    width: 'auto',
    display: 'flex',
  },
  iconAppSize: {
    height: 'auto',

    [theme.breakpoints.only('sm')]: {
      height: 40,
    },

    [theme.breakpoints.up('md')]: {
      height: '2.5rem',
    },
 
    [theme.breakpoints.down('xs')]: {
      height: 29
    }
  },
}));

class Home extends Component {
  static propTypes = {
    history: PropTypes.any,
    classes: PropTypes.any
  }

  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      selectedFeature: 0,
      slideIndex: 0,
      showMenu: true,
      response: {},
      faqList: [],
      promoList: [],
      faqChange: 0,
    };
  }

  componentDidMount() {
    // Get information homepage
    services.GetHomepage({
      success: (response) => {
        this.setState({ response: response });
      },
      error: () => {

      },
    });

    // Get Faq
    globalServices.getFaq({
      _limit: 5,
      // 'category.id': 1,
    }, (response, err) => {
      if (!err) {
        this.setState({ faqList: response });
      }
    });

    // Get Promo
    globalServices.getPromo({
      ...(window.matchMedia('(max-width: 600px)').matches) ? {_limit: 5} : {_limit: 12},
    }, (response, err) => {
      if (!err) {
        this.setState({ promoList: response });
      }
    });
  }


  href = (e, url, faqChange) => {
    e.preventDefault();
    this.props.history.push({
      pathname: url,
      state: { faqChange: faqChange }
    });
  }

  onSelectFeature = (index) => {
    this.setState({
      selectedFeature: index
    })
  }

  toogleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {
    const classes = this.props.classes;
    const settingsCarousel = {
      autoplay: true,
      dots: false,
      infinite: true,
      arrows: false,
      // speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ currentSlide: newIndex });
      }
    };

    const response = this.state.response;
    const title = response?.title;
    const description = response?.description;

    const titleScrolling = response?.titleScrollingText;
    const itemScrolling = response?.scrollingText;

    const faqList = this.state.faqList;
    const promoList = this.state.promoList;

    return (
      <>
        <Header toggleMenu={this.toogleMenu} showMenu={this.state.showMenu} />
        <div className={clsx(classes.headerContainer, 'relative')}>
          <div className={clsx(classes.header, 'flex items-start lg:items-center')}>
            <div className="flex flex-col items-start">
              <span className={classes.headerTitle} dangerouslySetInnerHTML={{ __html: title }} />
              <p className={classes.headerDescription} dangerouslySetInnerHTML={{ __html: description }} />

              <div className={clsx('mt-5')}>
                <Link to="/product_feature" className={classes.headerButton}>
                  Cari Tau
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.bannerVideo}>
            <video className={classes.video} autoPlay muted loop src={require('../../assets/media/AstraPay Website Header 296x462.mp4').default} />
          </div>
        </div>

        <FeatureHeader titleScrolling={titleScrolling} itemScrolling={itemScrolling} />

        <FeatureProduct />

        <div className="py-10 px-4 lg:px-0">
          <div className="flex items-center justify-between lg:justify-center">
            <span className="text-xl lg:text-4xl font-extrabold text-dark-grey lg:text-primary-blue" style={{ fontFamily: 'Nunito-Regular' }}>
              Promo
            </span>

            <Link to="/promo" className="font-inter font-semibold text-base text-primary-blue block lg:hidden">
              LIHAT SEMUA PROMO
            </Link>
          </div>
          <div className={clsx('w-full lg:w-10/12 xl:w-8/12 mx-auto', style.promoSlider)}>
            <div className="block lg:hidden my-5">
              <Carousel
                arrows={false}
                dots
                className="space-x-3"
                responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                {promoList.map((promo, index) => {
                  const url = `/promo/${promo.id}`;
                  const image = getImages(get(promo, 'image.url'));
                  const startDate = get(promo, 'startDate');
                  const endDate = get(promo, 'endDate');
                  const textDate = `Periode ${moment(startDate).format('D MMMM')} - ${moment(endDate).format('D MMMM YYYY')}`;

                  return (
                    <div className="px-3 py-3" key={index}>
                      <div className="w-full rounded-lg overflow-hidden" style={{ boxShadow: '0px 0px 12px 0.5px rgba(0, 0, 0, 0.12)' }}>
                        <img className="w-full" src={image} />
                        <div className="w-full flex flex-col px-5 py-2">
                          <p className="font-inter text-lg text-dark-grey font-semibold">
                            {promo.name}
                          </p>
                          <p className="font-inter text-sm text-grey-2 font-light">
                            {textDate}
                          </p>

                          <Link to={url} className="flex items-center justify-between mt-2">
                            <span className="font-inter font-semibold text-primary-blue">
                              SELENGKAPNYA
                            </span>


                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.33337 10H16.6667" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.8334 4.16666L16.6667 9.99999L10.8334 15.8333" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Carousel>
            </div>

            <div className="hidden lg:block my-20">
              <Carousel
                {...settingsCarousel}
                innerRef={slider => (this.slider = slider)}
                responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                {promoList.map((promo, index) => {
                  const image = getImages(get(promo, 'image.url'));
                  const startDate = get(promo, 'startDate');
                  const endDate = get(promo, 'endDate');
                  const textDate = `Periode ${moment(startDate).format('D MMMM')} - ${moment(endDate).format('D MMMM YYYY')}`;
                  const url = `/promo/${promo.id}`;

                  return (
                    <div className="px-3" key={index}>
                      <PromoCard
                        url={url}
                        className="w-full"
                        image={image}
                        title1={promo.title}
                        date={textDate}
                      />
                    </div>
                  )
                })}
              </Carousel>

              <div className="flex justify-center mt-10">
                <Pagination
                  variant="outlined"
                  count={(promoList.length / 3).toFixed()}
                  // page={(promo.length / this.state.currentSlide).toFixed()}
                  onChange={(ev, page) => this.slider.slickGoTo((promo.length / page).toFixed())}
                />
              </div>
            </div>
          </div>
          <div className="lg:flex justify-center hidden">
            <button onClick={(e) => this.href(e, '/promo')}
              style={{
                color: '#FFFFFF',
                borderRadius: '17.5px',
                fontFamily: 'Inter-Regular',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '17px',
                backgroundColor: '#0045E5',
                padding: '5px 39px 5px 39px',
                // marginLeft: '1rem'
              }}
            >
              Lihat Semua
            </button>
          </div>
        </div>

        <div style={{ backgroundColor: '#F7F7F7' }} className="py-5 px-3 lg:px-0">
          <div className="flex flex-col items-center justify-center">
            <span className="text-xl lg:text-4xl font-extrabold text-dark-grey lg:text-primary-blue" style={{ fontFamily: 'Nunito-Regular' }}>
              Partner Kami
            </span >
            <img className="hidden md:block lg:block" src={require('../../assets/banner/Partner Kami.png').default} alt="" />
            <img className="block md:hidden" src={require('../../assets/banner/mobileClient.png').default} alt="" />
          </div>
        </div>

        <div className={clsx(style.faqSection, 'flex-col lg:flex-row')} style={{ backgroundColor: '#0045E5' }}>
          <div className="mr-0 lg:mr-28 w-full lg:w-2/5 px-4 lg:px-0">
            <span className={classes.faqTitle}>
              FAQ
            </span>

            <div>
              {faqList.map((tab, index) => (
                <div key={index}>
                  <div>
                    <Link to={{
                      pathname: '/faq',
                      state: { faqChange: 'panel' + tab.id }
                    }}
                    >
                      <button
                        className={clsx(style.button, 'flex items-center rounded-md lg:rounded-lg')}
                      >
                        <div className={clsx(classes.faqItem, style.titleWrapper, 'flex items-center')}>
                          {tab.title}
                          <svg className="text-white" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 6.83325H14.3333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.5 1L14.3333 6.83333L8.5 12.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-right mt-5 block lg:hidden">
              <Link to="/faq" className="text-base font-semibold uppercase text-kuning-ap font-inter">
                LIHAT SEMUA FAQ
              </Link>
            </div>
          </div>

          <div className={clsx(style.faqText, 'hidden lg:block')}>
            <img className="" src={require('../../assets/banner/FAQ.png').default} width={150} />
            <div className="mt-3"
              style={{
                fontFamily: 'Inter-Regular',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#FFFFFF',
              }}
            >
              Ingin Cari Tahu yang Lain?
            </div>
            <button className="" onClick={(e) => this.href(e, '/faq')}
              style={{
                background: '#00DDC7',
                borderRadius: '17.5px',
                fontFamily: 'Inter-Regular',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '17px',
                color: '#003BC4',
                padding: '5px 39px 5px 39px',
                marginTop: '1.3rem',
                // marginLeft: '1rem'
              }}
            >
              Temukan Disini
            </button>
          </div>
        </div>

        <div className={classes.banner}>
          <div className={clsx(classes.bannerImage, 'flex flex-col justify-center relative h-full md:w-11/12 sm:w-full mx-auto')}>
            <span className={classes.bannerText}>
              Install dan Register AstraPay Sekarang!
            </span>

            <div className="flex flex-col lg:flex-row items-start lg:items-center lg:justify-start space-x-0 lg:space-x-3 space-y-2 lg:space-y-0 mt-5 lg:mt-5">
              <a className="mr-3" href="https://astrapay.onelink.me/9OXk/astrapaydownloadios">
                <img className={clsx("w-auto h-10 lg:h-10",classes.iconAppSize)} src={require('../../assets/banner/Badge Appstore.png').default} alt="" />
              </a>
              <a href="https://astrapay.onelink.me/8z2t/fc8b7d5f">
                <img className={clsx("w-auto h-10 lg:h-10",classes.iconAppSize)} src={require('../../assets/banner/Badge Google Play.png').default} alt="" ></img>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default withStyled(Home);