import { useState } from 'react';
import style from './index.module.scss';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
// import PropTypes from 'prop-types';

import LogoHorizontal from '../../assets/logo/logo_horizontal.png';
import MenuIcon from '../icon/menu.svg';
import menu from './menu';
import MobileNav from './MobileNav';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(prev => !prev);
  }

  return (
    <header>
      <div className={clsx(style.headerContainer)}>
        <Link className={style.astraLogo} to="/">
          <img src={require('../../assets/logo/Navbar - Logo.png').default} width="67.05" height="67" alt="" style={{ maxHeight: 67 }} />
        </Link>
        <div className="xl:flex lg:flex sm:hidden w-11/12	justify-evenly">
          {menu.map((item, index) => (
            <Link className={style.menuItem} to={item.href} key={index}>
              {item.name}
            </Link>
          ))}
        </div>
      </div>

      <div className="w-full flex items-center px-3 py-2 lg:hidden">
        <img src={MenuIcon} style={{ width: 33, height: 33 }} onClick={() => setIsOpen(true)} />
        <div className="ml-3">
          <Link to="/">
            <img src={LogoHorizontal} />
          </Link>
        </div>
      </div>

      <MobileNav isOpen={isOpen} toggleOpen={toggleOpen} />
    </header>
  );
}

Header.propTypes = {};

export default Header;