import axios from 'axios';
import endpoint from '../../../utils/endpoint';

async function getHomepage(callback){
  const url = endpoint.homepage;

  try {
    const response = await axios.get(url);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

export default {
  GetHomepage: getHomepage,
};