import Header from '../../../components/header';
import Footer from '../../../components/footer';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import services from '../services';
import { useEffect, useState } from 'react';
import getImages from '../../../utils/getImages';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E5E5E5',
  },
  width: {
    width: '80%',
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 0,
    },
  },
  image: {
    marginTop: '30px',
    width: '100%',
    height: 'auto',
  },
  titleText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '42px',
    lineHeight: '51px',
    color: '#444444',
    marginTop: '3.5rem',

    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  subTitleText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#999999',
    marginTop: '21px',
    marginBottom: '11px',
  },
  contentText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#444444',
    marginBottom: '10rem',

    [theme.breakpoints.between('md', 'lg')]: {
      marginRight: '10rem',
    }

  },
  submit: {
    backgroundColor: '#FF8500',
    color: 'white',
    padding: '6px 50px',
    borderRadius: '17.5px',
  },
}));

function PromoDetail() {
  const classes = useStyles();
  const { id } = useParams();
  const [detailResponse, setResponse] = useState({});

  useEffect(() => {
    services.getPromoById({ id }, (response, err) => {
      if (!err) {
        setResponse(response);
      }
    });
  }, []);

  const title = get(detailResponse, 'title');
  const image = getImages(get(detailResponse, 'image.url'));
  const startDate = get(detailResponse, 'startDate');
  const endDate = get(detailResponse, 'endDate');
  const textDate = `Periode ${moment(startDate).format('D MMMM')} - ${moment(endDate).format('D MMMM YYYY')}`;
  const content = get(detailResponse, 'detail');

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.width}>
        <div className="w-11/12 lg:w-9/12 flex flex-col mx-auto pb-10">
          <div className="w-full py-5" style={{ borderBottom: '0.5px solid #999999' }}>
            <Link to="/promo" className="flex items-center space-x-2 text-primary-blue">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3333 7.16675L0.999921 7.16675" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.83325 13L0.999921 7.16667L6.83325 1.33333" stroke="#003BC4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="font-inter font-normal text-base text-primary-blue">Back</span>
            </Link>
          </div>
          <img src={image} className={classes.image} />

          <div className={classes.titleText}>
            {title}
          </div>
          <div className={classes.subTitleText}>
            {textDate}
          </div>

          <div className="my-10" dangerouslySetInnerHTML={{ __html: `<div class="ck-content">${content}</div>` }} />

          <div>
            <button className={classes.submit} type="button">
              Dapatkan Promo
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PromoDetail;
