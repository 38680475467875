import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  // Feature Banner
  featureBannerHeadText: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#003BC4',
    fontSize: '30px',
    lineHeight: '53px',

    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      lineHeight: '30px',
    },
  },
  featureTitleText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#003BC4',
    marginTop: 10,

    [theme.breakpoints.only('md')]: {
      fontSize: 16,
      lineHeight: '14px',
    },

    [theme.breakpoints.only('sm')]: {
      fontSize: 14,
      lineHeight: '16px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 'normal'
    },
  },
  featureItem: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#003BC4',

    
    [theme.breakpoints.only('md')]: {
      fontSize: 16,
      lineHeight: '14px',
    },

    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
      lineHeight: '16px',
      fontWeight: 500,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 300,
    },
  },
  iconSize: {
    minHeight: 33,
    maxHeight: 33,
    height: 33,
    width: 33,
    minWidth: 33,
    maxWidth: 33,

    [theme.breakpoints.down('md')]: {
      minHeight: 17,
      maxHeight: 17,
      height: 17,
      width: 17,
      minWidth: 17,
      maxWidth: 17,
    },
  },
  iconAppSize: {
    height: 'auto',

    [theme.breakpoints.only('sm')]: {
      height: 40,
    },

    [theme.breakpoints.up('md')]: {
      height: '2.5rem',
    },
    
    [theme.breakpoints.down('xs')]: {
      height: 29
    }
  },
}));

function FeatureBanner() {
  const classes = useStyles();
  const classIcon = clsx('bg-primary-blue rounded-full flex items-center justify-center p-1', [classes.iconSize])

  return (
    <div className="pl-16 flex flex-col lg:flex-row lg:justify-evenly px-5 md:px-8 lg:px-0 bg-green-primer relative py-20">
      <div className="text-primary-blue w-full lg:w-4/12">
        <div className={classes.featureBannerHeadText}>
          Install, Register dan
          <br />
          Upgrade Akun-mu!
          <br />
        </div>
        <p className={classes.featureTitleText}>
          Keuntungan Menjadi Preferred User:
        </p>

        <ul className="space-y-2 mt-10 lg:mt-12">
          <li className="flex items-start space-x-2">
            <div className={classIcon}>
              <img src={require('../../../assets/icon/Transfer _ Topup/money.png').default} />
            </div>
            <span className={classes.featureItem}>
              Simpan saldo sampai 10 juta rupiah
            </span>
          </li>

          <li className="flex items-center space-x-2">
            <div className={classIcon}>
              <img src={require('../../../assets/icon/Transfer _ Topup/atom_icon_transfer_sesama white 1.png').default} />
            </div>

            <span className={classes.featureItem}>
              Transfer ke sesama member
            </span>
          </li>

          <li className="flex items-center space-x-2">
            <div className={classIcon}>
              <img src={require('../../../assets/icon/Transfer _ Topup/atom_icon_transfer_cashout white 1.png').default} />
            </div>

            <span className={classes.featureItem}>
              Tarik tunai
            </span>
          </li>
        </ul>

        <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row mt-16 lg:mt-8 xl:w-4/6 2xl:w-3/6">
          <a className="mr-3" href={'https://apps.apple.com/id/app/astrapay/id1487585085'}>
            <img className={classes.iconAppSize} src={require('../../../assets/banner/Badge Appstore.png').default} alt="" />
          </a>
          <a href={'https://play.google.com/store/apps/details?id=com.ada.astrapay&hl=in&gl=US'}>
            <img className={classes.iconAppSize} src={require('../../../assets/banner/Badge Google Play.png').default} alt="" ></img>
          </a>
        </div>
      </div>
      <img className="mt-0 w-6/12 absolute bottom-0 right-0 mb-12 lg:mb-0 lg:relative lg:mt-20 lg:w-1/3" src={require('../../../assets/image/Preferredz 1.png').default} alt="" />
      <img className="absolute top-0 right-0 hidden lg:block" src={require('../../../assets/image/Group 23.png').default} width={300} alt="" />
    </div>
  )
}

export default FeatureBanner;