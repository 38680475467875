import axios from 'axios';
import endpoint from '../../../utils/endpoint';

async function getFaq(params,callback) {
  const url = endpoint.faq;

  const config = {
    method: 'get',
    params,
  };

  try {
    const total = await axios(url + '/count', config);
    const response = await axios(url, config);
    await callback.success({ data: response.data, total });
  } catch (error) {
    await callback.error(error);
  }
}

async function getCategoryFaq(callback) {
  const url = endpoint.categoryFaq;

  try {
    const response = await axios.get(url);
    await callback.success(response);
  } catch (error) {
    await callback.error(error);
  }
}

export default {
  getFaq,
  getCategoryFaq
};