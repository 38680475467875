import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  root: {},
}))

function Button(props) {
  const { className, children, disabled, isLoading, ...other } = props;
  const classes = useStyles();
  const classDefault = 'w-full rounded-md bg-oren py-3 text-white font-inter font-semibold mt-8';
  const classButton = clsx(classes.root, className, classDefault, {
    'opacity-50': disabled,
    'opacity-100': !disabled,
  });

  return (
    <button className={classButton} disabled={disabled} {...other}>
      {isLoading ? (
        <CircularProgress color="#fff" size={22} />
      ): children }
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Button;