import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { usePagination } from '@material-ui/lab';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    height: 32,
    minWidth: 32,
    margin: '0 3px',
    padding: '0 6px',
    textAlign: 'center',
    boxSizing: 'border-box',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    borderRadius: 16,
    letterSpacing: '0.01071em'
  },
}));

function Pagination(props) {
  const { count, page, variant, onChange } = props;
  const classes = useStyles();
  const { items } = usePagination({
    page,
    onChange,
    count,
  });

  return (
    <ul className={clsx(classes.root, 'flex items-center space-x-4')}>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = '...';
        } else if (type === 'page') {
          const classActive = clsx(classes.button, 'transition duration-150 font-inter font-semibold focus:outline-none',
          {
            'hidden lg:block border-2 border-primary-blue text-primary-blue ease-in': selected && variant === 'outlined',
            'hidden lg:block border-2 border-2 border-white text-grey-2 ease-out': !selected && variant === 'outlined',
          },
          {
            'hidden lg:block bg-primary-blue text-white ease-in': selected && variant !== 'outlined',
            'hidden lg:block bg-white text-black ease-out': !selected && variant !== 'outlined',
          },
          );
          const mobileClassActive = clsx('lg:hidden font-inter',
          {
            'lg:hidden text-primary-blue': selected,
            'lg:hidden text-grey-2': !selected,
          },
          );
          children = (
            <>
              <button className={classActive} {...item}>
                {page}
              </button>
              <div className={mobileClassActive} {...item}>
                {page}/{count}
              </div>
            </>
          );
        } else if (type === 'previous') {
          const { disabled } = { ...item };
          const classPrev = clsx('transition duration-150 ease-in-out focus:outline-none', {
            'text-primary-blue': !disabled,
            'text-grey-2': disabled,
          });
          children = (
            <button className={classPrev} type="button" {...item}>
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3333 6.8335L0.999921 6.8335" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.83325 12.6667L0.999922 6.83341L6.83325 1.00008" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          )
        } else if (type === 'next') {
          const { disabled } = { ...item };
          const classNext = clsx('transition duration-150 ease-in-out focus:outline-none', {
            'text-primary-blue': !disabled,
            'text-grey-2': disabled,
          });
          children = (
            <button className={classNext} type="button" {...item}>
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6.83325H14.3333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.5 1L14.3333 6.83333L8.5 12.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          )
        }

        return <li key={index}>{children}</li>
      })}
    </ul>
  );
}

Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  onChange: () => {},
};

export default Pagination;