import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import menu from './menu';
import { Link } from 'react-router-dom';
import LogoHorizontal from '../../assets/logo/logo_horizontal.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 365,
    height: '100vh',
    backgroundColor: '#0045E5',
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

function MobileNav(props) {
  const classes = useStyles();
  const { isOpen, toggleOpen } = props;

  return (
    <SwipeableDrawer
      className="lg:hidden block"
      anchor="left"
      open={isOpen}
      onOpen={toggleOpen}
      onClose={toggleOpen}
    >
      <div className={clsx(classes.root, 'px-4 py-5')}>
        <div>
          <Link to="/">
            <img src={LogoHorizontal} />
          </Link>
        </div>
        <ul className="mt-5">
          {menu.map((item, index) => (
            <li key={index} className="py-3">
              <Link to={item.href} className="w-full block text-white font-inter text-lg font-semibold hover:text-white">{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </SwipeableDrawer>
  );
}

MobileNav.propTypes = {
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

MobileNav.defaultProps = {
  isOpen: false,
};

export default MobileNav;