import { useState } from 'react';

function usePagination() {
  const [total, setTotal] = useState(0);

  function setPagination(limit, count) {
    const totalPagination = count / limit;

    if (totalPagination > 1) {
      return setTotal((count / limit).toFixed());
    }

    return setTotal(1);

  }

  return [total, setPagination];
}

export default usePagination;