import axios from 'axios';
import endpoint from '../../../utils/endpoint';

async function getPartners(params, callback) {
  const url = endpoint.partner;
  const config = {
    method: 'get',
    params,
  };

  try {
    const total = await axios(url + '/count', config);
    const response = await axios(url, config);
    await callback({total, data: response.data}, null);
  } catch (error) {
    await callback(null, error);
  }
}
export default {
  getPartners
}