import Header from '../../components/header';
import Footer from '../../components/footer';
import style from './index.module.scss';
import Pagination from '../../components/pagination';
import usePagination from '../../utils/usePagination';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';

// Component
import Carousel from '../../components/carousel';
import Select from '../../components/select';

// Services
import services from './services';
import { useEffect, useState } from 'react';
import getImages from '../../utils/getImages';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#00DDC7',
    backgroundImage: `url('${require('../../assets/blog/hero.png').default}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '80% bottom',
    height: 500,

    [theme.breakpoints.down('md')]: {
      backgroundSize: 'auto 60%',
      height: 265,
    },

    [theme.breakpoints.only('md')]: {
      backgroundSize: 'auto 80%',
      height: 265,
    },
  },
  headerTitle: {
    fontFamily: 'Nunito-Regular',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '70px',
    lineHeight: '86px',
    color: theme.palette.primary.main,
    marginTop: 0,

    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '32px',
      marginTop: '10%',
    },

    [theme.breakpoints.only('md')]: {
      fontSize: '70px',
      lineHeight: '86px',
      marginTop: '1%',
    },
  },
  headerSubTitle: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#444',
    width: '40%',

    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main,
      width: '40%',
    },

    [theme.breakpoints.only('md')]: {
      color: '#444',
      width: '50%',
    },
  },
  sidebar: {
    marginLeft: '40%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '30%',
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: '10%',
    },
  },
  card: {
    maxHeight: 229,

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      maxHeight: 500,
    }
  },
}));

function Blog() {
  const classes = useStyles();
  const [params, setParams] = useState({
    _start: 0,
    _limit: 7,
    page: 1,
    category: null,
  });
  const [listBlog, setListBlog] = useState({
    total: 0,
    data: [],
  });
  const [listCategory, setListCategory] = useState([]);
  const [listBlogPopular, setListBlogPopular] = useState([]);
  const [total, setPagination] = usePagination();

  // const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    services.getBlog({
      _start: params._start,
      _limit: params._limit,
      ['category.id']: params.category,
    }, (response, err) => {
      if (!err) {
        setListBlog(response);
        setPagination(params._limit, response.total);
      }
    });
  }, [params]);

  useEffect(() => {
    services.getCategory((response, err) => {
      if (!err) {
        setListCategory(response);
      }
    });
    services.getBlogPopular((response, err) => {
      if (!err) {
        setListBlogPopular(response);
      }
    });
  }, []);

  const categoryList = [{ id: null, name: 'Semua article' }, ...listCategory];

  return (
    <div className="overflow-hidden">
      <Header />
      <div className={clsx(classes.header, 'flex')}>
        <div className="flex flex-col items-start justify-start lg:justify-center w-11/12 lg:w-8/12 mx-auto">
          <span className={classes.headerTitle}>
            Blog
          </span>
          <p className={clsx(classes.headerSubTitle, 'font-inter')}>
            Temukan artikel dan informasi menarik tentang AstraPay di sini.
          </p>
        </div>
      </div>

      <div className="flex w-full">
        <div className="hidden lg:flex lg:flex-col md:w-4/12 2xl:w-1/3 text-center pt-32" style={{ backgroundColor: '#F7F7F7' }}>

          <div className={clsx(classes.sidebar, 'flex flex-col space-y-8 items-start')}>
            <div className={style.inter800}>
              <span className="mb-3">
                KATEGORI
              </span>
            </div>

            {categoryList.map((categoryItem, index) => {
              const isActive = params.category === categoryItem.id;
              const classBorder = clsx('w-12', {
                'bg-primary-blue': isActive,
                'bg-article': !isActive,
              });

              return (
                <div key={index} className="block w-46">
                  <button
                    className={clsx(style.inter600, 'flex flex-col focus:outline-none')}
                    onClick={() => setParams(prev => ({ ...prev, category: categoryItem.id }))}
                  >
                    {categoryItem.name}
                    <div style={{ height: 5 }} className={classBorder}></div>
                  </button>
                </div>
              )
            })}
          </div>

          <div className={clsx(classes.sidebar, 'flex flex-col items-start mt-10')}>
            <div className={style.inter800}>
              <span className="mb-9">
                ARTIKEL POPULER
              </span>
            </div>

            <div>
              {listBlogPopular.map((popular, index) => {
                const url = `/blog/${popular.slug}`;
                const image = getImages(get(popular, 'image.url'));
                const category = get(popular, 'category.name');

                return (
                  <Link to={url} className="flex flex-col my-9" key={index}>
                    <img src={image} className="w-8/12" />
                    <div className="flex flex-col justify-between items-start">
                      <div className="w-9/12 text-left">
                        <p className={clsx(style.inter24, 'line-clamp-3')}>{popular.title}</p>
                      </div>
                      <div className={style.popularArticleCategory}>
                        <div className="text-left">
                          <span className={clsx(style.inter16, 'text-grey-2')}>
                            {category}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>

        </div>

        <div className="flex flex-col items-center w-full lg:w-3/4 pt-5 lg:pt-32 md:px-7">
          <div className="w-full block lg:hidden px-4 mb-8 mt-5">
            {/* <select
              className="form-input w-full rounded-md text-grey-2 font-inter font-semibold"
              style={{ border: '0.5px solid #999999' }}
              onChange={(ev) => setParams(prev => (ev.target.value === 'Semua article' ? { ...prev, category: null } : { ...prev, category: ev.target.value }))}
            >
              {categoryList.map((category, index) => (
                <option value={category.id} key={index}>
                  {category.name}
                </option>
              ))}
            </select> */}
            <div className="py-2">
              <Select items={categoryList} onChange={(ev) => setParams(prev => (ev.target.value === '0' ? { ...prev, category: null } : { ...prev, category: ev.target.value }))} options={null} />
            </div>
          </div>

          <div className="w-full lg:w-auto px-4 lg:px-0 flex flex-col space-y-4">
            {listBlog.data.map((blog, index) => {
              const title = get(blog, 'title');
              const url = `/blog/${blog.slug}`;
              const urlImage = get(blog, 'image.url');
              const category = get(blog, 'category.name');
              const date = get(blog, 'created_at');
              const dateFormat = moment(date).format('DD MMMM YYYY');
              const image = getImages(urlImage);

              return (
                <div className="pb-8" style={{ borderBottom: '1px solid #B2B2B2' }} key={index}>
                  <Link to={url}>
                    <div className={clsx(classes.card, 'flex flex-col lg:flex-row lg:space-x-8')}>
                      <img src={image} className="w-full lg:w-2/6 object-cover" />
                      <div className="flex flex-col space-y-3 w-full lg:justify-between mt-3 lg:mt-0">
                        <div className="space-y-3">
                          <div className={style.inter24} style={{ color: '#003BC4' }}>{title}</div>
                          <div
                            className={clsx(style.inter16Desc, 'line-clamp-4')}
                            dangerouslySetInnerHTML={{ __html: blog.content.replace(/( |<([^>]+)>)/ig, " ") }}
                          />
                        </div>
                        <div className="flex flex-row mt-2 space-x-2">
                          <div className={style.inter16SubCategory}>{category}</div>
                          <div className="mx-2 text-grey-2">|</div>
                          <div className={style.inter16SubCategory}>{dateFormat}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
          <div className="my-12 lg:32 ">
            <Pagination
              count={total}
              onChange={(event, page) => setParams(prev => ({ ...prev, _start: prev._limit * page }))}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-article pt-10 pb-20 mb-10 lg:hidden" style={{ minHeight: 200 }}>
        <div className="w-11/12 mx-auto">
          <p
            className="text-dark-grey text-2xl font-extrabold"
            style={{  fontFamily: 'Nunito-Regular'}}
          >
            Artikel Popular
          </p>
        </div>
        <Carousel arrows={false} dots>
          {listBlogPopular.map((itemCarousel) => {
            const url = `/blog/${itemCarousel.slug}`;
            const image = getImages(get(itemCarousel, 'image.url'));
            const category = get(itemCarousel, 'category.name');

            return (
              <div key={itemCarousel.key} className="px-3 py-3">
                <div className="w-full px-4 py-2 flex space-x-2 bg-white shadow-md rounded-md">
                  <img src={image} className="object-cover w-20 h-20 rounded-md" />
                  <div className="flex flex-col">
                    <Link to={url} className="text-lg font-semibold font-inter text-dark-grey line-clamp-2">
                      {itemCarousel.title}
                    </Link>
                    <span className="text-grey-2 uppercase font-inter">{category}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
      <Footer />
    </div>
  )
}

Blog.propTypes = {
  history: PropTypes.any,
  image: PropTypes.any,
  title1: PropTypes.string,
  title2: PropTypes.string,
  date: PropTypes.string,
};

export default Blog;