import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    border: '0.5px solid #999999',
  },
}));

function Input(props) {
  const { label, className, error, errorText, ...other } = props;
  const classes = useStyles();

  return (
    <div className="flex flex-col">
      <span className="font-inter font-normal text-base">{label}</span>
      <input
        className={clsx('form-input border boder-gray-300 px-4 py-2 rounded-md mt-2 w-full', classes.root, className)}
        {...other}
      />
      {error && (
        <p className="mt-2 font-inter text-red-500 text-base font-light">
          {errorText}
        </p>
      )}
    </div>
  );
}


Input.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
};

Input.defaultProps = {
  error: false,
};

export default Input;