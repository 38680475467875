// import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/header';
import Footer from '../../components/footer';

// Karir Component
import Header from './components/Header';
import FindKarir from './components/FindKarir';
import WorkWithAstra from './components/WorkWithAstra';
import Testimoni from './components/Testimoni';
import Keuntungan from './components/Keuntungan';

// Utils
import get from 'lodash/get';

// Services
import services from './services';
import globalServices from '../../services';
import { useEffect, useState } from 'react';

function Karir() {
  const [response, setReponse] = useState({});
  const [pageSetting, setPageSetting] = useState({});

  useEffect(() => {
    services.getKarir({
      success: (response) => {
        setReponse(response)
      },
      error: () => {},
    });
    globalServices.getPageSetting((response, err) => {
      if (!err) {
        setPageSetting(response);
      }
    });
  }, []);

  const workwithastra = get(response, 'listKeunggulan');
  const testimoni = get(response, 'listTestimony');
  const keuntungan = get(response, 'listKeuntungan');

  return (
    <div>
      <Navbar />
      <Header response={pageSetting} />
      <FindKarir response={pageSetting} />
      <WorkWithAstra list={workwithastra} />
      <Testimoni list={testimoni} />
      <Keuntungan list={keuntungan} />
      <Footer />
    </div>
  );
}

export default Karir;