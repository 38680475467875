import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Carousel from '../../../components/carousel';

// Utils
import get from 'lodash/get';
import getImages from '../../../utils/getImages';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    backgroundColor: '#F7F7F7',
    paddingTop: 76,
    paddingBottom: 115,
  },
  container: {
    backgroundPositionX: 'center',
    backgroundImage: `url(${require('../../../assets/karir/circle.png').default})`,
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('md')]: {
      backgroundImage: 'none',
    },
  },
  title: {
    fontFamily: 'Nunito-Regular',
    fontWeight: 800,
    fontSize: 45,
    lineHeight: '60px',
    color: '#003BC4',
    width: 650,
    textAlign: 'center',


    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '24px',
      color: '#444',
      width: '100%',
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    width: 550,
    padding: 39,
    borderRadius: 11,
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 12px 0.5px rgba(0, 0, 0, 0.12)',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,

    [theme.breakpoints.only('md')]: {
      width: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      width: '65%',
      flexDirection: 'column',
      alignItems: 'start',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 10,
      marginBottom: 10,
      height: 860
    },
  },
  cardText: {
    fontFamily: 'Inter-Regular',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
    color: '#444',
  },
  cardBody: {
    fontFamily: 'Inter-Regular',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#444',
    marginTop: 17,
  },
}));

function Testimoni(props) {
  const { list } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, 'flex flex-col items-center justify-center')}>
        <span className={classes.title}>
          Cerita Mereka
        </span>

        <div className="hidden md:mx-10 lg:grid grid-cols-2 grid-rows-2 gap-4 mt-12 ">
          {list.map((item, index) => {
            const employee = get(item, 'name');
            const position = get(item, 'jobPosition');
            const detail = get(item, 'detail');
            const image = getImages(get(item, 'image.url'));

            return (
              <div className={classes.card} key={index}>
                <img src={image} className="w-28 h-28" />

                <div className="flex flex-col ml-6">
                  <span className={classes.cardText}>{employee}</span>
                  <span className={classes.cardText}>{position}</span>

                  <p className={classes.cardBody}>
                    “{detail}”
                  </p>
                </div>
              </div>
            )
          })}
        </div>

        <div className="w-full mt-5 block lg:hidden">
          <Carousel
            slidesToShow={1}
            swipe
            arrows
            dots
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
          >
            {list.map((item, index) => {
              const employee = get(item, 'name');
              const position = get(item, 'jobPosition');
              const detail = get(item, 'detail');
              const image = getImages(get(item, 'image.url'));

              return (
                <div className="w-full" key={index}>
                  <div className={classes.card}>
                    <img src={image} className="lg:h-2" />

                    <div className="flex flex-col mt-5">
                      <span className={classes.cardText}>{employee}</span>
                      <span className={classes.cardText}>{position}</span>

                      <p className={classes.cardBody}>
                        “{detail}”
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

Testimoni.propTypes = {
  list: PropTypes.array,
};

Testimoni.defaultProps = {
  list: []
};

export default Testimoni;