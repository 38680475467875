import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Carousel from '../../../components/carousel';

// Utils
import get from 'lodash/get';
import getImages from '../../../utils/getImages';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    paddingTop: 80,
    paddingBottom: 80,

    [theme.breakpoints.down('md')]: {
      paddingTop: 65,
      paddingBottom: 65,
    },
  },
  title: {
    fontFamily: 'Nunito-Regular',
    fontWeight: 800,
    fontSize: 45,
    lineHeight: '60px',
    color: '#003BC4',
    width: 650,
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '24px',
      color: '#444',
      width: '100%',
    },
  },
  cardTitle: {
    fontFamily: 'Inter-Regular',
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'center',
    color: '#003BC4',

    [theme.breakpoints.down('md')]: {
      marginTop: 12,
      fontSize: 12,
      color: '#444',
    },
  },
  cardContainer: {
    marginTop: 42,
  },
  card: {
    width: 285,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardImage: {
    width: 107,
    height: 107,
    // backgroundColor: '#00DDC7',
    // borderRadius: '50%',

    [theme.breakpoints.down('md')]: {
      width: 69,
      height: 69,
    }
  },
  cardBody: {
    fontFamily: 'Inter-Regular',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    width: 'auto',
    marginTop: 10,

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '16px',
      width: 223,
    },
  },
}));

function WorkWithAstra(props) {
  const { list } = props;
  const classes = useStyles();
  const classCardContainer = clsx('lg:flex items-center justify-between w-8/12 mx-auto hidden', classes.cardContainer);

  return (
    <div className={classes.root}>
      <div className="flex flex-col items-center justify-center">
        <span className={classes.title}>Bekerja Bersama AstraPay</span>
      </div>

      <div className={classCardContainer}>
        {list.map((item, index) => {
          const title = get(item, 'name');
          const description = get(item, 'detail');
          const urlImage = get(item, 'image.url');
          const image = getImages(urlImage)

          return (
            <div className={classes.card} key={index}>
              <img className={classes.cardImage} src={image} />
              <span className={classes.cardTitle}>
                {title}
              </span>
              <p className={classes.cardBody}>
                {description}
              </p>
            </div>
          )
        })}
      </div>

      <div className="mt-5 block lg:hidden">
        <Carousel
          slidesToShow={1}
          swipe
          arrows
          dots
        >
          {list.map((item, index) => {
            const title = get(item, 'name');
            const description = get(item, 'detail');
            const urlImage = get(item, 'image.url');
            const image = getImages(urlImage)

            return (
              <div className="w-full flex justify-center" key={index}>
                <div className={classes.card}>
                  <img className={classes.cardImage}  src={image}/>
                  <span className={classes.cardTitle}>
                    {title}
                  </span>
                  <p className={classes.cardBody}>
                    {description}
                  </p>
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    </div >
  );
}

WorkWithAstra.propTypes = {
  list: PropTypes.array,
};

WorkWithAstra.defaultProps = {
  list: [],
};

export default WorkWithAstra;