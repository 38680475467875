import PropTypes from 'prop-types';

function Input(props) {
  const { error, errorText, ...other } = props;

  return (
    <div>
      <input
        {...other}
      />
      {error && (
        <div className="mt-2">
          <p className="text-base text-red-500 font-inter">
            {errorText}
          </p>
        </div>
      )}
    </div>
  );
}

Input.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
};

export default Input;