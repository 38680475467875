import axios from 'axios';
import endpoint from '../utils/endpoint';

async function getPageSetting(callback) {
  const url = endpoint.pageSetting;

  try {
    const response = await axios(url);
    callback(response.data, null);
  } catch (error) {
    callback(null, error);
  }
}

async function getProduct(params, callback) {
  const url = endpoint.product;

  try {
    const response = await axios.get(url, { params });
    callback(response.data, null);
  } catch (error) {
    callback(null, error);
  }
}


async function getCategoryProduct(callback) {
  const url = endpoint.categoryProduct;

  try {
    const response = await axios.get(url);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getFaq(params, callback) {
  const url = endpoint.faq;

  try {
    const response = await axios.get(url, { params });
    await callback(response.data, null);
    console.log(response.data);
  } catch (error) {
    await callback(null, error);
  }
}

async function getPromo(params, callback) {
  const url = endpoint.promo;

  try {
    const response = await axios.get(url, { params });
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getTnc(callback) {
  const url = endpoint.tnc;

  try {
    const response = await axios.get(url);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getPrivacy(callback) {
  const url = endpoint.privacy;

  try {
    const response = await axios.get(url);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

export default {
  getCategoryProduct,
  getPageSetting,
  getProduct,
  getPromo,
  getFaq,
  getTnc,
  getPrivacy,
};