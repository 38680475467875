// import React from 'react'
import Header from '../../components/header';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useState, useEffect } from 'react';

// Utils 
import globalServices from '../../services';
import moment from 'moment';

const styles = (theme) => ({
  width: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  titleFont: {
    color: '#003BC4',
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '36px',
    textalign: 'center',
  },
  lastUpdateFont: {
    fontfamily: 'Inter-Regular',
    fontstyle: 'normal',
    fontweight: 'normal',
    fontsize: '12px',
    lineheight: '15px',
    color: '#999999',
    textalign: 'center',
    marginTop: '10px',
  },
  border: {
    border: '1px solid #B2B2B2',
    marginTop: '1.25rem',
  }
});

function Terms(props) {
  const {classes} = props;

  const [tncData, setTncData] = useState([])

  useEffect(() => {
    globalServices.getTnc((response, err) => {
      if (!err) {
        setTncData(response);
      }
    });
  }, []);
  const tncDate = moment(tncData.updated_at).format('DD MMMM YYYY');
  return (
    <>
      <Header />
      <div className="container">
        <div className={clsx(classes.width,"mx-40")}>
          <div className="flex flex-row justify-between mt-28">
            <div className={classes.titleFont}>
              Ketentuan Penggunaan AstraPay
            </div>
            <div className={classes.lastUpdateFont}>
              Terakhir Diperbarui pada tanggal {tncDate}
            </div>
          </div>
          <div className={classes.border}></div>
          <div className="mb-20">
            <div className="mb-28 mt-6" dangerouslySetInnerHTML={{__html: `<div class="ck-content">${tncData.content}</div>`}} />
          </div>
        </div>
      </div>
    </>
  )
}

Terms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Terms);