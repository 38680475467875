import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

function Loading(props) {
  const { className, isLoading, isEmpty, children } = props;

  if (!isLoading && !isEmpty) {
    return children;
  }

  if (!isLoading && isEmpty) {
    return (
      <div className={clsx(className, 'w-full flex items-center justify-center')}>
        <p className="font-inter text-2xl font-semibold text-dark-grey text-center">
          Mohon maaf kami tidak menemukan <br /> data yang anda inginkan.
        </p>
      </div>
    )
  }

  return (
    <div className={clsx(className, 'w-full flex items-center justify-center')}>
      <CircularProgress />
    </div>
  )

}

Loading.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  children: PropTypes.node,
};

Loading.defaultProps = {
  isLoading: false,
  isEmpty: false,
};

export default Loading;
