import { useRef } from 'react';
import style from './index.module.scss'
import Header from '../../components/header';
import Footer from '../../components/footer';
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../../components/button';
import Input from './components/Input';
import TextArea from './components/Textarea';

import { reCaptcha } from '../../utils/endpoint';

// Utils
import services from './services';
import { useState } from 'react';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: '1',
    height: '100vh',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },

    [theme.breakpoints.only('md')]: {
      height: '150vh'
    },

    [theme.breakpoints.only('lg')]: {
      height: '130vh'
    },
  },
  input: {
    width: '100%',
    padding: '12px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    resize: 'vertical',
  },
  submit: {
    display: 'flex',
    marginTop: '1rem',
    backgroundColor: '#FF8500',
    color: 'white',
    padding: '6px 55px',
    border: 'none',
    borderRadius: '17.5px',
    cursor: 'pointer',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      justifyContent: 'center',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '6px',
    },
  },
  backgroundImage: {
    backgroundImage: `url('${require('../../assets/image/helpCenter/Group 163.png').default}')`,
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',

    [theme.breakpoints.down('md')]: {
      backgroundImage: `url('${require('../../assets/image/helpCenter/Group 164.png').default}')`,
    }
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  paddingLeftSide: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1rem',
    },
  },
  mobileTextDescWrapper: {
    marginTop: '2rem',
    marginBottom: '1.75rem',

    [theme.breakpoints.up('sm')]: {
      width: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      color: '#444444',
      backgroundColor: '#F7F7F7',
    },
  },
  textInterSmall: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '5rem',
      marginBottom: '10px',
      fontFamily: 'Inter-Regular',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  imageBottom: {

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottomRightRadius: '30px',
      borderBottomLeftRadius: '30px',
      color: '#003BC4',
      width: '1.5rem',
    },

    [theme.breakpoints.up('md')]: {
      display: 'none',
      visibility: 'hidden'
    },
  },
  leftSideDescText: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    paddingTop: '24px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontWeight: '300',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#444444',
      backgroundColor: '#F7F7F7',
    },
  },
  rightSide: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      marginTop: '3.313rem',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flex: 1,
    }
  },
  form: {

    [theme.breakpoints.down('sm')]: {
      marginRight: '3rem',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      width: '90%'
    },
  },
  formWrapper: {
    textAlign: 'center',
    marginBottom: '7rem',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      paddingBottom: '4.4rem',
    },

    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },
  captchaForm: {
    [theme.breakpoints.down('sm')]: {
      width: '10%',
    }
  },
  buttonWhatsApp: {
    border: '4px solid #00DDC7',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px 0.5px rgba(0, 0, 0, 0.12)',
    borderRadius: '31.5px',
    marginTop: '25px',
    width: '276px',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      background: '#00DDC7',
      borderRadius: '6px',
      justifyContent: 'space-evenly',
      width: '85%',
    }
  },
  insideWhatsApp: {
    alignItems: 'center',
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'row',
    background: '#F7F7F7',
    borderRadius: '26.5px',
    margin: '5px 5px 5px 5px',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  textFont: {
    [theme.breakpoints.down('md')]: {
      marginTop: '48px',
      fontFamily: 'Nunito-Regular',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '45px',
      lineHeight: '61px',
      color: '#FFFFFF',
    }
  },
  mobileFont: {
    fontFamily: 'Inter-Regular',
    fontStyle: 'normal',
    fontHeight: '300',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#FFFFFF',
    paddingTop: '28px',
    paddingBottom: '60px'
  }
}));

const newTicketValidation = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().required(),
  topic: Yup.string().required(),
  message: Yup.string().required(),
  secured: Yup.boolean(true).required('Anda harus mencentang reCAPTCHA terlebih dahulu'),
});

function HelpCenter() {
  const classes = styles();
  const refCaptch = useRef(null);
  const [message, setMessage] = useState({
    open: false,
    severity: '',
    message: '',
  });
  const [copy, setCopy] = useState({
    value: '021-1500-793',
    copied: false,
  })
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      topic: '',
      message: '',
      secured: '',
    },
    validationSchema: newTicketValidation,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { name, email, topic, message } = values;
      setSubmitting(true);

      services.postTicket({
        name,
        email,
        topic,
        message,
      },
        (response, err) => {
          setSubmitting(false);

          if (!err) {
            resetForm();
            refCaptch.reset();
            return setMessage({
              open: true,
              severity: 'success',
              message: 'Ticket berhasil terkirim'
            })
          }

          return setMessage({
            open: true,
            severity: 'error',
            message: 'Ticket tidak berhasil di kirim',
          });
        });
    }
  });

  const { handleSubmit, values, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, setErrors, isSubmitting, errors } = formik;


  function onChange(value) {
    if (value) {
      setFieldValue('secured', true);
    }
  }

  function onExpired() {
    setFieldTouched('secured', true);
    setFieldValue('secured', '');
    setErrors({
      secured: 'Anda harus mencentang reCAPTCHA terlebih dahulu'
    });
  }

  return (
    <>
      <Header />
      <div className={classes.container}>
        <div className={clsx(classes.leftSide), 'hidden lg:flex lg:w-1/2'}>
          <div className={classes.backgroundImage}>
            {/* <div className={classes.overlay}></div> */}
            <div className="lg:px-32 lg:pt-20">
              <div className={classes.paddingLeftSide}>
                <div className={style.textFont}>
                  Ada yang Bisa
                  <br />
                  Kami Bantu?
                </div>
                <br />
                <br />
                <div className={classes.textInterSmall}>
                  <div className={clsx(style.textInter, 'hidden lg:flex')}>
                    Hubungi
                  </div>
                  <CopyToClipboard text={copy.value} onCopy={() => setCopy({ copied: true })}>
                    <div role="button" className={classes.buttonWhatsApp}>
                      <div className={classes.insideWhatsApp}>
                        <PhoneIcon style={{ color: '#003BC4' }} />
                        <div className={style.textInter800}>
                          (021) 1500-793
                        </div>
                        <ArrowForwardIosIcon style={{ color: '#003BC4' }} />
                      </div>
                    </div>
                  </CopyToClipboard>
                  <div className={classes.buttonWhatsApp}>
                    <a href={'https://yuk.chat/AstraPay'}>
                      <div className={classes.insideWhatsApp}>
                        <WhatsAppIcon style={{ color: '#003BC4' }} />
                        <div className={style.textInter800}>
                          WhatsApp
                        </div>
                        <ArrowForwardIosIcon style={{ color: '#003BC4' }} />
                      </div>
                    </a>
                  </div>
                </div>
                <div className={style.textInter24}>
                  Jam Operasi 08.00 - 21.00 WIB <br />
                  Senin - Minggu
                </div>
                <div className="mt-8">
                  <hr className={style.hr} />
                  <img className={clsx(style.imageBottom, classes.imageBottom)} src={require('../../assets/icon/Vector 16 down.png').default} />
                </div>
              </div>
              <div className={classes.leftSideDescText}>
                PT. ASTRA DIGITAL ARTA
                Menara Astra Lantai 56
                Jalan Jenderal Sudirman Kav 5-6, RT. 10 RW. 11
                Karet Tengsin, Tanah Abang, Jakarta Pusat
                DKI Jakarta – 10220
              </div>
            </div>
          </div>
        </div>
        <div className={clsx('lg:hidden px-10', classes.backgroundImage)}>
          <div className={classes.textFont}>
            Ada yang Bisa Kami
            <br />
            Bantu?
          </div>
          <a href="tel:021-1500-793" role="button" className={classes.buttonWhatsApp}>
            <PhoneIcon style={{ color: '#003BC4' }} />
            <div className={style.textInter800}>
              (021) 1500-793
            </div>
            <ArrowRightIcon style={{ color: '#003BC4' }} fontSize={'large'} />
          </a>
          <a className={classes.buttonWhatsApp} href={'https://yuk.chat/AstraPay'}>
            <WhatsAppIcon style={{ color: '#003BC4' }} />
            <div className={style.textInter800}>
              WhatsApp
            </div>
            <ArrowRightIcon style={{ color: '#003BC4' }} fontSize={'large'} />
          </a>
          <div className={classes.mobileFont}>
            Jam Operasi 08.00 - 21.00 WIB Senin - Minggu
          </div>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.form}>
            <div className={clsx(style.textInterWindows, 'lg:mt-28 md:mt-10')} style={{ color: '#003BC4' }}>
              Kirim Pesan
            </div>
            <div className="w-80 mb-10">
              Silahkan isi formulir di bawah ini, AstraPay Care akan segera menjawab pesanmu.
            </div>
          </div>

          <div className={classes.formWrapper}>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-5 mt-5">
              <Input
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                className={classes.input}
                type="text"
                placeholder="Nama"
                error={Boolean(touched.name && errors.name)}
                errorText={touched.name && errors.name}
              />

              <Input
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                className={classes.input}
                type="email"
                placeholder="Email"
                error={Boolean(touched.email && errors.email)}
                errorText={touched.email && errors.email}
              />

              <Input
                name="topic"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.topic}
                className={classes.input}
                type="text"
                placeholder="Topik"
                error={Boolean(touched.topic && errors.topic)}
                errorText={touched.topic && errors.topic}
              />

              <TextArea
                name="message"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.message}
                className={classes.input}
                placeholder="Pesan"
                style={{ height: '124px' }}
                error={Boolean(touched.message && errors.message)}
                errorText={touched.message && errors.message}
              />

              <div className="flex flex-col">
                <ReCAPTCHA
                  ref={refCaptch}
                  sitekey={reCaptcha}
                  onChange={onChange}
                  onExpired={onExpired}
                  size={'normal'}
                  className={classes.captchaForm}
                />
                <div className="mt-3">
                  {touched.secured && errors.secured && (
                    <p className="text-base font-inter text-red-500">{errors.secured}</p>
                  )}
                </div>
              </div>

              <Collapse in={message.open}>
                <Alert
                  severity={message.severity}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setMessage({ open: false, error: '', message: '' });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {message.message}
                </Alert>
              </Collapse>

              <Button isLoading={isSubmitting} disabled={isSubmitting} className="rounded-full">
                Kirim
              </Button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

HelpCenter.propTypes = {
  classes: PropTypes.any
}

export default (HelpCenter)
