import axios from 'axios';
import endpoint from '../../../utils/endpoint';

async function getBlog(params, callback) {
  const url = endpoint.blog;
  const config = {
    method: 'get',
    params,
  };

  try {
    const total = await axios(url + '/count', config);
    const response = await axios(url, config);
    await callback({ total, data: response.data }, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getCategory(callback) {
  const url = endpoint.categoryBlog;
  const config = {
    method: 'get',
  };

  try {
    const response = await axios(url, config);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getBlogById(params, callback) {
  const url = endpoint.blog;

  try {
    const response = await axios.get(url, { params });

    // Count View Article
    axios.get(`${url}/${response.data[0].id}/view`);
    await callback(response.data[0], null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getBlogPopular(callback) {
  const url = endpoint.articlePopular;

  try {
    const response = await axios.get(url);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

export default {
  getBlog,
  getBlogById,
  getCategory,
  getBlogPopular,
};